import React from 'react';

/*const icons = {
  'menu': 'M0 3h20v2h-20v-2zM0 9h20v2h-20v-2zM0 15h20v2h-20v-2z',
  'timer': 'M16.32 7.1c1.044 1.339 1.675 3.045 1.675 4.898 0 4.418-3.582 8-8 8s-8-3.582-8-8c0-4.068 3.036-7.426 6.965-7.934l0.040-0.004v-2.060h2v2.060c1.46 0.18 2.8 0.76 3.9 1.62l1.46-1.46 1.42 1.42-1.46 1.45zM10 18c3.314 0 6-2.686 6-6s-2.686-6-6-6v0c-3.314 0-6 2.686-6 6s2.686 6 6 6v0zM7 0h6v2h-6v-2zM12.12 8.46l1.42 1.42-3.54 3.52-1.41-1.4 3.53-3.54z',
  'close': 'M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z'
};*/

import { ReactComponent as MenuIcon } from '../../../assets/images/menu.svg';
import { ReactComponent as XIcon } from '../../../assets/images/x.svg';
import { ReactComponent as AtIcon } from '../../../assets/images/at.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/arrow-down.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/images/checkmark.svg';
import { ReactComponent as MinusIcon } from '../../../assets/images/minus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';

const icons = {
  'menu': MenuIcon,
  'close': XIcon,
  'at': AtIcon,
  'arrow-down': ArrowDownIcon,
  'checkmark': CheckmarkIcon,
  'minus': MinusIcon,
  'plus': PlusIcon,
};

const Icon = (props) => {
  const IconComponent = icons[props.name];

  return <IconComponent className={props.className} />;
};

export default Icon;
