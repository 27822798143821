import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import Modal from 'react-modal';
import {version} from './version';

import { init as mixpanelInit, track } from './mixpanel';
import history from './history';
import { loadState, saveSate, getBuildVersion, saveBuildVersion } from './localstorage';
import throttle from 'lodash/throttle';

import './index.module.css';
import App from './App';
import Loading from './components/Loading/Loading'
import firebase from './firebase';
import {fetchFromFirebase, setConnectionStatus} from './store/actions/user'

import authReducer from './store/reducers/auth';
import userReducer from './store/reducers/user';
import logbookReducer from './store/reducers/logbook';
import * as Sentry from "@sentry/react";
import {syncQueuedWorkouts} from "./store/actions/logbook";

require('dotenv').config();

// Initialize Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: `logbook@${version}`,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllInputs: false,
      maskAllText: false
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  // Session Replay
  replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
  replaysOnErrorSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
});

// Initialize MixPanel
mixpanelInit(process.env.REACT_APP_MIXPANEL_TOKEN);

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  logbook: logbookReducer,
});

const persistedState = loadState();

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, persistedState, composeEnhancers(
  applyMiddleware(thunk)
));

store.subscribe(throttle(() => {
  saveSate(store.getState())
}, 1000));

const app = (
  <Provider store={store}>
    <Router history={history}>
      <App/>
    </Router>
  </Provider>
);

let hasRendered = false;
const renderApp = async () => {
  checkVersion();

  if (!hasRendered) {
    ReactDOM.render(app, document.getElementById('root'));
    hasRendered = true;
  }
};

function checkVersion() {
  const versionRef = firebase.database().ref().child('version');
  versionRef.on('value', (data) => {
    const currentBuildVersion = data.val();
    const previousBuildVersion = getBuildVersion();

    if (currentBuildVersion !== previousBuildVersion) {
      saveBuildVersion(currentBuildVersion);
      window.location.reload();
    }
  });
}

Modal.setAppElement('#root');
ReactDOM.render(<Loading/>, document.getElementById('root'));

let initialConnection = true;
let lastDisconnect = null;
const connectedRef = firebase.database().ref('.info/connected');
connectedRef.on('value', (snap) => {
  if (snap.val() === true) {
    const queueState = store.getState();
    const queueLength = queueState.logbook.queue.length;
    const offlineMs = lastDisconnect != null ? new Date() - lastDisconnect : 0;
    lastDisconnect = null;
    console.log(`Firebase connected; offline duration: ${offlineMs}ms; initial connection: ${initialConnection}; workout queue length: ${queueLength}`);

    try {
      track('Firebase connected', {
        offline_duration_ms: offlineMs, initial_connection: initialConnection, workout_queue_length: queueLength
      });
    } catch {
      // Ignored
    }

    store.dispatch(syncQueuedWorkouts()).then(() => {
      console.log(`Firebase queued workout synchronization finished`);
    }).catch((e) => {
      console.log(`Firebase queued workout synchronization failed; ${e}`);
    }).finally(() => {
      store.dispatch(setConnectionStatus(true));
      initialConnection = false;
    });
  } else {
    lastDisconnect = new Date();
    console.log('Firebase disconnected');

    try {
      track('Firebase disconnected');
    } catch {
      // Ignored
    }

    store.dispatch(setConnectionStatus(false));
  }
});
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
firebase.auth().onAuthStateChanged(user => {
  console.log('Auth state changed');
  if (user) {
    store.dispatch(fetchFromFirebase());
  } else {
    track('Logout done');
  }

  renderApp();
});

function isTextInput(node) {
  return ['INPUT', 'SELECT'].indexOf(node.nodeName) !== -1;
}
document.addEventListener('touchstart', function(e) {
  if (!isTextInput(e.target) && isTextInput(document.activeElement)) {
    document.activeElement.blur();
  }
}, false);

(() => {
  // Ensure service worker exists prior to attempting to unregister
  // Related: Sentry LOGBOOK-8
  if (typeof navigator.serviceWorker === "undefined") {
    return;
  }

  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (let registration of registrations) {
      registration.unregister();
    }
  })
})()
