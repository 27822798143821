import React, { Component } from 'react';
import { connect } from 'react-redux';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import history from './../../history';
import View from 'components/View/View';
import Footer from 'components/View/Footer/Footer';
import StyledButton from 'components/UI/StyledButton/StyledButton';
import StyledButtonWrap from 'components/UI/StyledButton/StyledButtonWrap/StyledButtonWrap';
import SelectList from 'components/UI/SelectList/SelectList';
import Navbar from 'components/View/Navbar/Navbar';
import NavbarTitle from 'components/View/Navbar/NavbarTitle/NavbarTitle';
import NavbarLeft from 'components/View/Navbar/NavbarLeft/NavbarLeft';
import NavbarRight from 'components/View/Navbar/NavbarRight/NavbarRight';
import NavbarButton from 'components/View/Navbar/NavbarButton/NavbarButton';
import Page from 'components/View/Page/Page';
import PageContent from 'components/View/PageContent/PageContent';
import AdvancedList from 'components/advanced-list';
import { updateExercises, deleteWorkout } from 'store/actions/logbook';
import styles from './EditWorkout.module.css';
import constants from '../../constants';

class EditWorkout extends Component {
  state = {
    isShortList: true,
    selected: [],
    workoutSelected: [],
    deleteConfirmed: false,
  };

  constructor(props) {
    super(props);

    this.state.isSetup = this.props.location.search.includes(`setup=1`) > 0;

    if (props.location.state) {
      const { workout, workoutType, workoutId } = props.location.state;

      this.state.workout = workout;
      this.state.workoutId = workoutId;
      this.state.workoutType = workoutType;
      const selected = reduce(workout.exercises, (acc, exercise) => [...acc, exercise.id], []);
      this.state.selected = selected;
      this.state.workoutSelected = selected;
    }
  }

  updateWorkout = () => {
    this.props.updateExercises(
      this.state.workoutId,
      this.state.selected,
      this.props.config,
    );
    history.goBack();
  }

  clickHandler = exerciseId => {
    let selected = [...this.state.selected];
    if (selected.indexOf(exerciseId) !== -1) {
      selected = selected.filter(ex => ex !== exerciseId);
    } else {
      selected = selected.concat(exerciseId);
    }
    this.setState({ selected });
  };

  goToLogbook = () => history.replace('/logbook');

  deleteHandler = () => {
    this.props.onDelete(this.state.workoutId, this.goToLogbook);
  };

  confirmDelete = () => {
    this.setState({ deleteConfirmed: true });
  }

  showAdvancedListClicked = () => this.setState({ isShortList: false });

  renderBottomButton = (disabled) => {
    let caption = 'Delete Workout';
    let clicked = this.confirmDelete;
    const disabledWhite = disabled ? true : false;

    if (this.state.deleteConfirmed) {
      caption = 'Tap Again to Confirm';
      clicked = this.deleteHandler;
    }

    return (
      <StyledButton
        clicked={clicked}
        disabled={disabled}
        disabledWhite={disabledWhite}
      >
        <StyledButtonWrap caption={caption} />
      </StyledButton>
    );

  }

  render() {
    const { selected, workoutSelected, workoutType } = this.state;
    const submitDisabled = selected.length === 0;
    const isTodayWorkout = workoutType === constants.WORKOUT_TYPES.Today;
    const selectLiftsTitle = isTodayWorkout ? `Select today's lifts.` : `Select your lifts.`;

    let list = Object.keys(this.props.config)
      .map(exerciseId => {
        const exercise = this.props.config[exerciseId];

        return {
          id: exercise.id,
          title: exercise.title,
          selected: selected.indexOf(exercise.id) !== -1,
          _pos: exercise.pos,
          _mandatory: exercise.mandatory
        };
      })
      .sort((a, b) => a._pos - b._pos);

    if (this.state.isShortList) {
      list = list.filter(exercise => exercise._mandatory);
    }

    const submit = !isEqual(selected, workoutSelected) && selected.length !== 0;

    return (
      <View>
        <Navbar>
          <NavbarLeft>
            <NavbarButton clicked={history.goBack}>cancel</NavbarButton>
          </NavbarLeft>
            <NavbarTitle title='Edit Workout' />
          <NavbarRight>
            <NavbarButton submit={submit} disabled={!submit} clicked={this.updateWorkout}>Save</NavbarButton>
          </NavbarRight>
        </Navbar>
        <Page>
          <PageContent withNavbar>
            <div>
              <div className={styles.title}>{selectLiftsTitle}</div>
              <SelectList list={list} clicked={this.clickHandler} />
              {this.state.isShortList && (
                <AdvancedList onClick={this.showAdvancedListClicked} />
              )}
            </div>
          </PageContent>
          <Footer>{this.renderBottomButton(submitDisabled)}</Footer>
        </Page>
      </View>
    );
  }
}

const mapStateToProps = reduxState => ({
  config: reduxState.logbook.config,
  workouts: reduxState.logbook.workouts,
});

const mapDispatchToProps = dispatch => ({
  onDelete: (workoutId, done) => dispatch(deleteWorkout(workoutId, done)),
  updateExercises: (workoutId, exercises, config) => dispatch(updateExercises(workoutId, exercises, config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditWorkout);
