import React from 'react';

import classes from './SelectItem.module.css';
import Icon from '../../Icon/Icon';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  let klasses = [classes.Item];
  if (props.selected) {
    klasses.push(classes.Selected)
  }
  if (props.lasOne) {
    klasses.push(classes.LasOne)
  }
  return (
    <div className={klasses.join(' ')} onClick={props.clicked}>
      <div className={classes.Content}>
        <div>{props.title}</div>
        {props.selected && <div className={classes.Check}><Icon name='checkmark'/></div>}
      </div>
    </div>
  )
}
