import { LOCAL_STORAGE_KEY } from './config';
import { updateObject } from './shared/utility';
//
import { initialState as authInitialState } from './store/reducers/auth';
import { initialState as userInitialState } from './store/reducers/user';
import { initialState as logbookInitialState } from './store/reducers/logbook';

export const getBuildVersion = () => {
  return localStorage.getItem('version');
}

export const saveBuildVersion = (version) => {
  return localStorage.setItem('version', version);
}

export const loadState = () => {
  // return undefined;
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    let state = JSON.parse(serializedState);

    // extend initialStates
    return {
      auth: updateObject(authInitialState, state.auth),
      user: updateObject(userInitialState, state.user),
      logbook: updateObject(logbookInitialState, state.logbook),
    };
  } catch (err) {
    return undefined;
  }
};

export const saveSate = reduxState => {
  try {
    const dataState = {};
    const reducers = Object.keys(reduxState);
    reducers.forEach(reducer => {
      dataState[reducer] = {};
      Object.keys(reduxState[reducer]).forEach(reducerPropertyKey => {
        // don't save keys begin with "_" (this is private state keys)
        if (!/^_/.test(reducerPropertyKey)) {
          dataState[reducer][reducerPropertyKey] =
            reduxState[reducer][reducerPropertyKey];
        }
      });
    });

    const serializedState = JSON.stringify(dataState);
    localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
  } catch (err) {
    console.error(err);
  }
};
