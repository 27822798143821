import { postRequest } from '../../helpers/api/api.client';

export const buildUrl = url => `/account${url}`;

export const signin = params => postRequest(buildUrl('/signin'), params);

export const setExternalId = params => postRequest(buildUrl('/set-external-id'), params);

export const getFirebaseToken = (accessToken) => postRequest(buildUrl('/firebase'), {}, {
  'Authorization': `Bearer ${accessToken}`,
});
