import React, { Component } from 'react';

import classes from './PageContent.module.css';

export default class PageContent extends Component {
  render () {
    const styles = {};
    if (this.props.withNavbar) {
      styles['paddingTop'] = `50px`;
    }
    if (this.props.withFooter) {
      styles['paddingBottom'] = `80px`;
    }
    if (this.props.footerHeight) {
      styles['paddingBottom'] = `${this.props.footerHeight}px`;
    }
    if (this.props.whiteBg) {
      styles['backgroundColor'] = '#FFFFFF';
    }
    return <div className={classes.PageContent} style={styles}>{this.props.children}</div>
  }
}