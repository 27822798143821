import React from 'react';

import styles from './WarmupItem.module.css';
import history from './../../../history';

import InputBox from 'components/Exercise/InputBox/InputBox';
import Icon from 'components/UI/Icon/Icon';
import StyledButton from 'components/UI/StyledButton/StyledButton';

export default function WarmupItem(props) {
  const openPlates = () => {
    history.push({
      pathname: '/plates',
      state: {
        weight: props.weight,
        exerciseId: props.exerciseId,
        subtitle: `${props.title} Warmups - ${props.reps} @ ${props.weight} lbs`,
      }
    });
  };

  return (
    <div className={styles.Wrap}>
      <div className={styles.Block}>
        <InputBox
          caption='Reps'
          value={props.reps}
          disabled
        />
      </div>
      <div className={styles.Icon}><Icon name='at'/></div>
      <div className={styles.pounds}>
        <InputBox
          caption='Pounds'
          value={props.weight}
          disabled
          full
        />
      </div>
      <div className={styles.plates}>
        <StyledButton btnType='SilverFull' clicked={openPlates}>View Plates</StyledButton>
      </div>
    </div>
  )
}
