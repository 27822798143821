import React from "react";
import scrollIntoView from 'scroll-into-view';

import classes from "./Input.module.css";

export default class Input extends React.Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();
  }

  preventDefault = evt => evt.preventDefault();

  onFocus = evt => scrollIntoView(evt.target, { time: 300, align: { top: 0.3 } }, () => {
    if (!this.input.current) {
      return;
    }

    this.input.current.focus();
  });

  render() {
    const {
      value,
      inputType,
      placeholder,
      disabled,
      changed,
      min,
      max,
      blured,
      suffix,
    } = this.props;
    const formattedValue = Number.isNaN(value) ? '' : value;

    const klasses = [classes.Default];
    if (inputType) {
      klasses.push(classes[inputType]);
    }

    return (
      <div className={classes.FlexContainer}>
        <input
          ref={this.input}
          pattern="[0-9]*"
          className={klasses.join(" ")}
          type={inputType}
          value={formattedValue}
          placeholder={placeholder}
          disabled={disabled}
          onChange={changed}
          min={min}
          max={max}
          onFocus={this.onFocus}
          onBlur={blured}
          onMouseUp={this.preventDefault}
        />
        {suffix && <span className={classes.Suffix}>{suffix}</span>}
      </div>
    );
  }
}

Input.defaultProps = {
  inputType: "number"
};
