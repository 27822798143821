import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import debug from 'debug';
import moment from 'moment';

import history from '../../history';
import View from '../../components/View/View';
import Navbar from '../../components/View/Navbar/Navbar';
import NavbarTitle from '../../components/View/Navbar/NavbarTitle/NavbarTitle';
import NavbarLeft from '../../components/View/Navbar/NavbarLeft/NavbarLeft';
import NavbarButton from '../../components/View/Navbar/NavbarButton/NavbarButton';
import NavbarRight from '../../components/View/Navbar/NavbarRight/NavbarRight';
import Page from '../../components/View/Page/Page';
import PageContent from '../../components/View/PageContent/PageContent';
import Exercise from '../../components/Exercise/Exercise';
import Footer from '../../components/View/Footer/Footer';
import StyledButton from '../../components/UI/StyledButton/StyledButton';
import StyledButtonWrap from '../../components/UI/StyledButton/StyledButtonWrap/StyledButtonWrap';
import Notes from '../TodayWorkout/Note';

import SummaryModal from "./summary";

import { isWorkoutValid } from "../../helpers/common";

import * as logbookActions from '../../store/actions/logbook';

const log = debug('Logbook:LogbookWorkout');

class LogbookWorkout extends Component {
  state = {
    workoutId: null,
    deleteConfirmed: false,
    isSummaryShown: false,
  };

  constructor(props) {
    super(props);

    if (props.match.params && props.match.params.item) {
      this.state.workoutId = props.match.params.item;
    }
  }

  componentDidMount() {
    log('EditHistoryWorkout componentDidMount');

    if (!this.props.location.state || !this.props.location.state.isCreation) {
      this.props.onDone(this.state.workoutId);
    }
  }

  onUpdateExerciseSets = (exerciseId, uuid, update) => {
    this.props.onUpdateExerciseSets(this.state.workoutId, exerciseId, uuid, update);
  }

  onRemoveExerciseSet = (exerciseId, uuid) => {
    this.props.onRemoveExerciseSet(this.state.workoutId, exerciseId, uuid);
  }

  onAddExerciseSet = (exerciseId, set) => {
    this.props.onAddExerciseSet(this.state.workoutId, exerciseId, set);
  }

  goToLogbook = () => history.replace('/logbook');

  handleCancel = (isCompleated) => () => {
    this.goToLogbook();
  }

  deleteHandler = () => {
    this.props.onDelete(this.state.workoutId, this.goToLogbook);
  };

  confirmDelete = () => {
    this.setState({ deleteConfirmed: true });
  }

  addWorkoutHandler = () => {
    this.props.onDone(this.state.workoutId, () => {
      this.setState({ isSummaryShown: true });
    });
  };

  renderBottomButton = (isWorkoutCompleated, disabled) => {
    let caption = 'Add Workout';
    let clicked = this.addWorkoutHandler;
    const disabledWhite = disabled ? true : false;

    if (isWorkoutCompleated) {
      caption = 'Delete Workout';
      clicked = this.confirmDelete;
    }
    if (this.state.deleteConfirmed) {
      caption = 'Tap Again to Confirm';
      clicked = this.deleteHandler;
    }

    return (
      <StyledButton
        clicked={clicked}
        disabled={disabled}
        disabledWhite={disabledWhite}
      >
        <StyledButtonWrap caption={caption} />
      </StyledButton>
    );

  }

  render() {
    const { workoutId } = this.state;
    const workout = this.props.workouts[workoutId];

    if (!workout) {
      return (
        <Redirect
          to={{
            pathname: '/logbook'
          }}
        />
      );
    }

    const isWorkoutCompleated = workout.isCompleated;
    const title = isWorkoutCompleated ? 'Edit Workout' : 'Add Workout';
    const workoutDate = moment(workout.date).local().format('ddd, MMM D, YYYY');
    const disabled = !isWorkoutValid(workout);

    return (
      <View>
        <Navbar>
          <NavbarLeft>
            <NavbarButton clicked={this.handleCancel(isWorkoutCompleated)}>Back</NavbarButton>
          </NavbarLeft>
          <NavbarTitle
            title={title}
            subtitle={moment(workoutDate).format('ddd, MMM D, YYYY')}
          />
          <NavbarRight empty />
        </Navbar>
        <Page>
          <PageContent withNavbar>
            {sortBy(workout.exercises, 'pos').map((exercise) => (
              <Exercise
                key={exercise.id}
                id={exercise.id}
                history={isWorkoutCompleated}
                showPlates
                edit
                title={exercise.title}
                exerciseSets={exercise.exerciseSets}
                onUpdateExerciseSets={this.onUpdateExerciseSets}
                onRemoveExerciseSet={this.onRemoveExerciseSet}
                onAddExerciseSet={this.onAddExerciseSet}
              />
            ))}
            <Notes workoutId={workoutId} history={isWorkoutCompleated} />
            <SummaryModal workout={workout} isOpen={this.state.isSummaryShown} />
          </PageContent>
          <Footer>
            {this.renderBottomButton(isWorkoutCompleated, disabled)}
          </Footer>
        </Page>
      </View>
    );
  }
}

const mapStateToProps = reduxState => {
  return {
    workouts: reduxState.logbook.workouts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDone: (workoutId, callback) => dispatch(logbookActions.compleateWorkout(workoutId, callback)),
    onDelete: (workoutId, done) => dispatch(logbookActions.deleteWorkout(workoutId, done)),
    onUpdateExerciseSets: (workoutId, exerciseId, uuid, update) => dispatch(logbookActions.updateExerciseSets(workoutId, exerciseId, uuid, update)),
    onRemoveExerciseSet: (workoutId, exerciseId, uuid) => dispatch(logbookActions.removeExerciseSet(workoutId, exerciseId, uuid)),
    onAddExerciseSet: (workoutId, exerciseId, set) => dispatch(logbookActions.addExerciseSet(workoutId, exerciseId, set)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogbookWorkout);
