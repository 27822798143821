import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import Auth from './containers/Auth/Auth';

import Logout from './containers/Auth/Logout/Logout';

import UserProfile from './containers/UserProfile/UserProfile';

import Statistics from './containers/Statistics/Statistics';

import Logbook from './containers/Logbook/Logbook';

import CreateWorkout from './containers/CreateWorkout/CreateWorkout';

import TodayWorkout from './containers/TodayWorkout/TodayWorkout';

import LogbookWorkout from './containers/LogbookWorkout/LogbookWorkout';

import EditWorkout from './containers/EditWorkout/EditWorkout';

import Plate from 'containers/Plate/Plate';
import Warmup from 'containers/Warmup/Warmup';
import NextWorkout from './containers/NextWorkout/NextWorkout';
import {auth as firebaseAuth} from "./firebase";

class App extends Component {
  render() {
    let routes = (
      <Switch>
        <ProtectedRoute path="/" exact component={TodayWorkout} isAuthenticated={this.props.isAuthenticated} />
        <ProtectedRoute path="/profile" component={UserProfile} isAuthenticated={this.props.isAuthenticated} />
        <ProtectedRoute path="/logout" component={Logout} isAuthenticated={this.props.isAuthenticated} />
        <ProtectedRoute path="/statistics" component={Statistics} isAuthenticated={this.props.isAuthenticated} />
        <ProtectedRoute path="/plates" exact component={Plate} isAuthenticated={this.props.isAuthenticated} />
        <ProtectedRoute path="/warmups" exact component={Warmup} isAuthenticated={this.props.isAuthenticated} />
        <ProtectedRoute path="/edit-workout" component={EditWorkout} isAuthenticated={this.props.isAuthenticated} />
        <ProtectedRoute path="/logbook" exact component={Logbook} isAuthenticated={this.props.isAuthenticated} />
        <ProtectedRoute path="/logbook/:item" exact component={LogbookWorkout} isAuthenticated={this.props.isAuthenticated} />
        <ProtectedRoute path="/workout/create" exact component={CreateWorkout} isAuthenticated={this.props.isAuthenticated} />
        <ProtectedRoute path="/workout/today" exact component={TodayWorkout} isAuthenticated={this.props.isAuthenticated} />
        <ProtectedRoute path="/workout/next" exact component={NextWorkout} isAuthenticated={this.props.isAuthenticated} />
        <Redirect to="/" />
      </Switch>
    );

    if (!this.props.isAuthenticated) {
      // only one Auth for unauthenticated user
      routes = (
        <Switch>
          <Route path="/" exact component={Auth} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return routes;
  }
}

function ProtectedRoute({component: Component, isAuthenticated, ...rest}) {
    return (<Route
        {...rest}
        render={props => isAuthenticated ? (<Component {...props} />) : (<Redirect to="/"/>)}
    />);
}

const mapStateToProps = reduxState => {
  return {
    isAuthenticated: reduxState.auth.userId !== null && firebaseAuth.currentUser !== null
  };
};

export default withRouter(connect(mapStateToProps)(App));
