import React from 'react';
import map from 'lodash/map';
import last from 'lodash/last';

import history from './../../history';
import Icon from '../UI/Icon/Icon';
import StyledButton from '../UI/StyledButton/StyledButton';

import classes from './Exercise.module.css';

import ExerciseValues from './ExerciseValues/ExerciseValues';

export default function Exercise(props) {
  const [editMode, setEditMode] = React.useState(false);
  const changedHandler = (uuid, { target: { value } }, key) => {
    props.onUpdateExerciseSets(props.id, uuid, { [key]: Number(value) || 0 });
  };

  const onRemoveExerciseSets = (uuid) => {
    props.onRemoveExerciseSet(props.id, uuid);
  }

  const onAddExerciseSet = () => {
    const keys = Object.keys(props.exerciseSets);

    let { pos, ...restExerciseSet } = props.exerciseSets[last(keys)];

    if (isNaN(pos)) {
      pos = 999;
    }

    props.onAddExerciseSet(props.id, { ...restExerciseSet, pos: pos + 1 });
  }

  const openPlates = () => {
    const [key] = Object.keys(props.exerciseSets);
    const exerciseSet = props.exerciseSets[key];

    history.push({
      pathname: '/plates',
      state: {
        weight: exerciseSet.weight,
        exerciseId: props.id,
        subtitle: `${props.title} - ${exerciseSet.weight} lbs`
      }
    });
  };

  React.useEffect(() => {
    if (!props.exerciseSets) {
      props.onAddExerciseSet(props.id, { pos: 1, sets: 1, reps: 5, weight: 45 });
    }
    // eslint-disable-next-line
  }, []);

  if (!props.exerciseSets) {
    return null;
  }

  const isLast = Object.keys(props.exerciseSets).length === 1;

  return (
    <div className={props.lastOne ? classes.LastOne : classes.Exercise}>
      <div className={classes.Head}>
        <div className={props.history ? classes.TitleBlue : classes.Title}>{props.title}</div>
        <div className={classes.Actions}>
          {props.showPlates && <div className={classes.Edit} onClick={openPlates}>Plates</div>}
          {props.edit && !editMode && <div className={classes.Edit} onClick={() => setEditMode(true)}>Edit</div>}
          {props.edit && editMode && <div className={classes.Save} onClick={() => setEditMode(false)}>Save</div>}
        </div>
      </div>

      {map(props.exerciseSets, (item, uuid) => (
        <ExerciseItem
          key={uuid}
          isLast={isLast}
          item={item}
          uuid={uuid}
          editMode={editMode}
          onRemoveExerciseSets={onRemoveExerciseSets}
          weightChangedHandler={changedHandler}
          setsChangedHandler={changedHandler}
          repsChangedHandler={changedHandler}
        />
      ))}
      {editMode && <StyledButton btnType="SilverMedium" clicked={onAddExerciseSet}>
        Add Set
      </StyledButton>}
    </div>
  )
}

function ExerciseItem({
  item,
  uuid,
  isLast,
  editMode,
  setsChangedHandler,
  repsChangedHandler,
  weightChangedHandler,
  onRemoveExerciseSets,
}) {
  return (
    <div className={classes.ExerciseItem}>
      {editMode && !isLast && <div className={classes.Icon} onClick={() => onRemoveExerciseSets(uuid)}>
        <Icon name="minus" />
      </div>}
      <ExerciseValues
        key={uuid}
        sets={item.sets}
        setsChanged={event => setsChangedHandler(uuid, event, 'sets')}
        reps={item.reps}
        repsChanged={event => repsChangedHandler(uuid, event, 'reps')}
        weight={item.weight}
        weightChanged={event => weightChangedHandler(uuid, event, 'weight')}
      />
    </div>
  );
}