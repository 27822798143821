import * as React from 'react';
import scrollIntoView from 'scroll-into-view';
import endsWith from 'lodash/endsWith';

import styles from './styles.module.css';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  max,
  min,
  value: initValue,
  onChange,
  onBlur,
  inputMode,
  type,
  suffix,
  placeholder,
  disabled,
  inputClassName,
  className
}) => {
  const ref = React.useRef(null);
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const onChangeInput = (event) => {
    const isAllowed = !/^[0-9.]*$/g.test(event.target.value);
    const isMaximum = max && Number(event.target.value) > max;
    const isModuleByHalf = Number(event.target.value) % 0.5 === 0;

    if (isAllowed || isMaximum || !isModuleByHalf) {
      return;
    }

    setValue(event.target.value);

    if (Number(event.target.value) <= 999 && !endsWith(event.target.value, '.')) {
      return onChange(event.target.value);
    }
  };

  const onFocus = evt => scrollIntoView(evt.target, { time: 300, align: { top: 0.3 } }, () => {
    if (!ref.current) {
      return;
    }

    ref.current.focus();
  });

  return (
    <div className={className}>
      <input
        ref={ref}
        pattern="[0-9.]*"
        className={inputClassName}
        type={type}
        inputMode={inputMode}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChangeInput}
        min={min}
        max={max}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {suffix && <span className={styles.Suffix}>{suffix}</span>}
    </div>
  )
}
