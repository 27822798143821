import React from 'react';

import StyledButton from 'components/UI/StyledButton/StyledButton';

import styles from './styles.module.css';

export default function AdvancedList(props) {
  return (
    <div className={styles.advanced}>
      <StyledButton btnType='SilverBig' clicked={props.onClick}>
        View Advanced List
      </StyledButton>
    </div>
  )
}
