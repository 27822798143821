import React, { Component } from 'react';

import classes from './ExerciseValues.module.css';

import Icon from '../../UI/Icon/Icon';
import InputBox from '../InputBox/InputBox';


export default class ExerciseValues extends Component {
  render () {
    return (
      <div className={classes.Wrap}>
        <div className={classes.Block}>
          <InputBox
            caption='Sets'
            max="99"
            value={this.props.sets}
            changed={this.props.setsChanged}
          />
        </div>
        <div className={classes.Icon}><Icon name='close'/></div>
        <div className={classes.Block}>
          <InputBox
            caption='Reps'
            max="99"
            value={this.props.reps}
            changed={this.props.repsChanged}
          />
        </div>
        <div className={classes.Icon}><Icon name='at'/></div>
        <div className={classes.Block}>
          <InputBox
            caption='Pounds'
            max="999"
            pattern='[0-9.]*'
            value={this.props.weight}
            changed={this.props.weightChanged}
            wide
          />
        </div>
      </div>
    )
  }

}