import React from 'react';
import sortBy from 'lodash/sortBy';
import ClampLines from 'react-clamp-lines';
import map from 'lodash/map';
import classes from './TrainingItem.module.css';
import DateFormat from '../../../components/DateFormat/DateFormat';
import moment from 'moment';

// eslint-disable-next-line import/no-anonymous-default-export
export default props => {
  let itemClass = moment(props.date).isAfter(new Date(), 'day')
    ? classes.Next
    : moment(props.date).isBefore(new Date(), 'day')
      ? null
      : classes.Today;

  const editLabel = moment(props.date).isAfter(new Date(), 'day')
    ? 'Open'
    : moment(props.date).isBefore(new Date(), 'day')
      ? 'Edit'
      : 'Edit';

  return (
    <div className={props.lastOne ? classes.LastOne : classes.TrainingItem}>
      <div onClick={props.editClicked} className={classes.Head}>
        <div className={classes.Date}>
          <div className={itemClass}>
            <DateFormat date={props.date} />
          </div>
        </div>
        <div className={classes.Edit} onClick={props.editClicked}>
          <div className={itemClass}>{editLabel}</div>
        </div>
      </div>
      <div onClick={props.editClicked}>
        {sortBy(props.exercises, 'pos').map(item => (
          <WorkoutItem key={item.id} item={item} />
        ))}
      </div>
      {props.note && <div>
        <div className={classes.NotesTitle}>NOTES</div>
        <ClampLines
          text={props.note}
          id="really-unique-id"
          lines={2}
          ellipsis="..."
          moreText="Tap for More"
          lessText="Tap for Less"
          className={classes.Notes}
          innerElement="p"
        />
      </div>}
    </div>
  );
};

function WorkoutItem({ item }) {
  const { exerciseSets } = item;

  return map(exerciseSets, (i, uuid) => (
    <div key={uuid} className={classes.Row}>
      <div className={classes.Exercise}>{item.title}</div>
      <div className={classes.ExerciseValues}>
        <div className={classes.ExerciseItem}>{i.sets}</div>
        <div className={classes.ExerciseItem}>x</div>
        <div className={classes.ExerciseItem}>{i.reps}</div>
        <div className={classes.ExerciseItem}>@</div>
        <div className={classes.ExerciseItem}>{i.weight}</div>
        <div>lbs</div>
      </div>
    </div>
  ))
}
