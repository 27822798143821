import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import values from 'lodash/values';
import reduce from 'lodash/reduce';
import moment from 'moment';
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import SelectBox from '../../components/UI/Selectbox/Selectbox';
import ItemInner from '../../components/UI/ItemInner/ItemInner';
import { track } from '../../mixpanel';
import styles from './Statistics.module.css';

import Spinner from '../../components/UI/Spinner/Spinner';
import View from '../../components/View/View';
import Navbar from '../../components/View/Navbar/Navbar';
import NavbarRight from '../../components/View/Navbar/NavbarRight/NavbarRight';
import NavbarTitle from '../../components/View/Navbar/NavbarTitle/NavbarTitle';
import NavbarLeft from '../../components/View/Navbar/NavbarLeft/NavbarLeft';
import DrawerToggler from '../../components/View/SideDrawer/DrawerToggler/DrawerToggler';
import Page from '../../components/View/Page/Page';
import PageContent from '../../components/View/PageContent/PageContent';

const measurementsOptions = [
  { id: 'bodyWeight', title: 'Weight' },
  { id: 'waistSize', title: 'Waist' },
  { id: 'hipSize', title: 'Hip' },
  { id: 'chestSize', title: 'Chest' },
]

const measurementToLengthTitle = {
  bodyWeight: 'Pounds',
  waistSize: 'Inches',
  hipSize: 'Inches',
  chestSize: 'Inches',
}

const getHighestByWeight = (exerciseSets) => reduce(
  exerciseSets,
  (a, v) => (v.weight > a.weight ? v : a),
  { weight: 0 },
);

class Logbook extends Component {
  state = {
    ref: null,
    measurementRef: null,
    showSideDrawer: false,
    exercise: '',
    measurement: 'bodyWeight',
    exercises: [],
  };

  static getDerivedStateFromProps(props, state) {
    const exercises = Object.keys(props.config)
      .map(exerciseId => {
        const exercise = props.config[exerciseId];

        return {
          id: exercise.id,
          title: exercise.title,
          _pos: exercise.pos,
        };
      })
      .sort((a, b) => a._pos - b._pos);

    if (!state.exercise) {
      return {
        exercises,
        exercise: exercises[0].id,
      };
    }

    return { exercises };
  }

  componentDidMount() {
    track("Open 'My progress' page");
  }

  sideDrawerClosedHandler = () => this.setState({ showSideDrawer: false });
  sideDrawerTogglerHandler = () => this.setState(prevState => ({ showSideDrawer: !prevState.showSideDrawer }));

  setRef = (e) => {
    if (!this.state.ref) {
      this.setState({ ref: e });
    }
  }

  setMeasurementRef = (e) => {
    if (!this.state.measurementRef) {
      this.setState({ measurementRef: e });
    }
  }

  onExerciseChange = ({ target }) => {
    this.setState({ exercise: target.value });
  }

  onMeasurementChange = ({ target }) => {
    this.setState({ measurement: target.value });
  }

  render() {
    const { exercise, exercises, measurement } = this.state;

    const data = values(this.props.workouts || {})
      .filter(workout => workout.exercises && workout.exercises[exercise] && moment(workout.date).isBefore(moment()))
      .sort((a, b) => a.timestamp - b.timestamp)
      .map((workout) => ({
        x: moment(workout.date).format('M/D/YY'),
        y: getHighestByWeight(workout.exercises[exercise].exerciseSets).weight,
      }));

    const measurementData = this.props.profileHistory
      .sort((a, b) => a.timestamp - b.timestamp)
      .map((profile) => ({
        x: moment(profile.date).format('M/D/YY'),
        y: profile[measurement],
      }));

    return (
      <View
        showSideDrawer={this.state.showSideDrawer}
        sideDrawerClosedHandler={this.sideDrawerClosedHandler}
      >
        <Navbar>
          <NavbarLeft>
            <DrawerToggler clicked={this.sideDrawerTogglerHandler} />
          </NavbarLeft>
          <NavbarTitle title="My progress" leftOffset />
          <NavbarRight />
        </Navbar>
        <Page>
          {this.props.loading &&
            <PageContent withNavbar>
              <Spinner />
            </PageContent>
          }
          {!this.props.loading &&
            <PageContent withNavbar>
              <div ref={this.setRef} className={styles.Wrapper}>
                <div className={styles.Filters}>
                  <ItemInner label='Lift'>
                    <SelectBox
                      options={exercises}
                      value={exercise}
                      title={this.props.config[exercise] ? this.props.config[exercise].title : ''}
                      changed={this.onExerciseChange}
                      fullWidth
                    />
                  </ItemInner>
                </div>
                {this.state.ref && exercise ? (
                  <AreaChart width={this.state.ref.clientWidth - 20} height={200} data={data} margin={{ top: 5, right: 5, bottom: 5, left: 0 }}>
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#db4453" stopOpacity={0.8}/>
                        <stop offset="100%" stopColor="#db4453" stopOpacity={0}/>
                      </linearGradient>
                    </defs>
                    <CartesianGrid vertical={false} stroke="#ccc" />
                    <XAxis dataKey="x" minTickGap={20} interval='preserveStartEnd' />
                    <YAxis tickCount={3} />
                    <Tooltip formatter={(value) => { return [`${value} Pounds`, ''] }} separator="" />
                    <Area type="monotone" dataKey="y" stroke="#db4453" fillOpacity={1} fill="url(#colorUv)" />
                  </AreaChart>
                ) : null}
              </div>
              <div ref={this.setMeasurementRef} className={styles.Wrapper}>
                <div className={styles.Filters}>
                  <ItemInner label='Body measurements'>
                    <SelectBox
                      options={measurementsOptions}
                      value={measurement}
                      title={measurementsOptions.find(opt => opt.id === measurement).title}
                      changed={this.onMeasurementChange}
                      fullWidth
                    />
                  </ItemInner>
                </div>
                {this.state.measurementRef && this.props.profileHistory.length > 0 ? (
                  <AreaChart width={this.state.measurementRef.clientWidth - 20} height={200} data={measurementData} margin={{ top: 5, right: 5, bottom: 5, left: 0 }}>
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#db4453" stopOpacity={0.8}/>
                        <stop offset="100%" stopColor="#db4453" stopOpacity={0}/>
                      </linearGradient>
                    </defs>
                    <CartesianGrid vertical={false} stroke="#ccc" />
                    <XAxis dataKey="x" minTickGap={20} interval="preserveStartEnd" />
                    <YAxis tickCount={3} />
                    <Tooltip formatter={(value) => { return [`${value} ${measurementToLengthTitle[measurement]}`, ''] }} separator="" />
                    <Area type="monotone" dataKey="y" stroke="#db4453" fillOpacity={1} fill="url(#colorUv)" />
                  </AreaChart>
                ) : null}
              </div>
            </PageContent>
          }
        </Page>
      </View>
    );
  }
}

const mapStateToProps = reduxState => ({
  loading: reduxState.logbook._loading,
  config: reduxState.logbook.config,
  workouts: reduxState.logbook.workouts,
  profileHistory: reduxState.user.profileHistory,
});

export default withRouter(
  connect(mapStateToProps)(Logbook)
);
