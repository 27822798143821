import React, { Component } from 'react';
import { connect } from 'react-redux';
import { track } from '../../../mixpanel';
import { Redirect } from 'react-router-dom';

import * as authActions from '../../../store/actions/auth'

class Logout extends Component {
  componentDidMount() {
    track('Logout clicked');
    this.props.onLogout();
  }

  render () {
    return <Redirect to={'/'}/>;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(authActions.logout())
  }
};

export default connect(null, mapDispatchToProps)(Logout);