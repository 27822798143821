import React from 'react';
import ReactModal from 'react-modal';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    zIndex: 10000,
    overflow: 'auto'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '200px'
  }
};

export default class Modal extends React.Component {
  render () {
    return <ReactModal
      isOpen={this.props.isOpen}
      style={customStyles}>{this.props.children}</ReactModal>
  }
}
