import React from 'react';
import moment from 'moment';

import styles from './styles.module.css';

export default class DateInput extends React.Component {
  state = {
    value: ''
  };

  componentDidMount() {
    if (this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  inputRef = React.createRef();

  onChange = e => {
    this.setState({ value: e.target.value });
    this.props.onChange(e.target.value);
  };
  onClick = () => this.inputRef.current.focus();

  renderDayOfWeek = value => {
    const formattedValue = value
      ? moment(value, 'YYYY-MM-DD').format('ddd, MMM D, YYYY')
      : '— Select Date —';
    return <div className={styles.value}>{`${formattedValue}`}</div>;
  };

  render() {
    const { value } = this.state;

    const min =
      this.props.minDate ||
      moment()
        .subtract(120, 'day')
        .format('YYYY-MM-DD');
    const max =
      this.props.maxDate ||
      moment()
        .add(120, 'day')
        .format('YYYY-MM-DD');
    return (
      <div className={styles.full} onClick={this.onClick}>
        <div className={styles.label}>Date</div>
        {this.renderDayOfWeek(value)}
        <div className={styles.inputBox}>
          <input
            min={min}
            max={max}
            ref={this.inputRef}
            className={styles.input}
            type="date"
            value={value}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }
}

DateInput.defaultProps = {
  onChange: () => {}
};
