import React from 'react';

import classes from './PlateIcon.module.css'

import { ReactComponent as plate0dot5 } from 'assets/images/plate-0dot5.svg';
import { ReactComponent as plate0dot75 } from 'assets/images/plate-0dot75.svg';
import { ReactComponent as plate1 } from 'assets/images/plate-1.svg';
import { ReactComponent as plate1dot25 } from 'assets/images/plate-1dot25.svg';
import { ReactComponent as plate2dot5 } from 'assets/images/plate-2dot5.svg';
import { ReactComponent as plate5 } from 'assets/images/plate-5.svg';
import { ReactComponent as plate10 } from 'assets/images/plate-10.svg';
import { ReactComponent as plate25 } from 'assets/images/plate-25.svg';
import { ReactComponent as plate45 } from 'assets/images/plate-45.svg';

const plates = {
  '0.5': plate0dot5,
  '0.75': plate0dot75,
  '1': plate1,
  '1.25': plate1dot25,
  '2.5': plate2dot5,
  '5': plate5,
  '10': plate10,
  '25': plate25,
  '45': plate45,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const Icon = plates[props.weight];

  return <div className={classes.PlateIcon}><Icon /></div>;
}
