import React from 'react';

import classes from './StyledButtonWrap.module.css';

export default class StyledButtonWrap extends React.Component {
  render() {
    let leftIcon;
    let rightIcon;
    if (this.props.leftIcon) {
      leftIcon = <div className={classes.LeftIcon}>{this.props.leftIcon}</div>
    }
    if (this.props.rightIcon) {
      rightIcon = <div className={classes.RightIcon}>{this.props.rightIcon}</div>
    }
    return (
      <div className={classes.Wrap}>
        {leftIcon}
        <span className={classes.Caption}>{this.props.caption}</span>
        {rightIcon}
      </div>
    )
  }
}