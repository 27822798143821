import React, { Component } from 'react';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';

import { track } from 'mixpanel';
import styles from './styles.module.css';

import StyledButton from 'components/UI/StyledButton/StyledButton';
import Modal from 'components/Modal/Modal';
import history from './../../../history';

class Summary extends Component {
  componentDidMount() {
    track('Today Summary Opened');
  }

  doItLaterHandler() {
    track('Do It Later Clicked');
    history.replace('/logbook');
  }

  createNextHandler() {
    track('Create Next Workout Clicked');
    history.replace('/workout/create');
  }

  render() {
    const { workout } = this.props;

    return (
      <Modal isOpen={this.props.isOpen} shouldCloseOnOverlayClick={false}>
        <div className={styles.TodaySummary}>
          <h1 className={styles.Title}>Nice Work!</h1>
          <p className={styles.NoteTitle}>Here's what you did today:</p>
          <div className={styles.Summary}>
            {sortBy(workout.exercises, 'pos').map((exercise) => (
              <div key={exercise.id}>
                {map(exercise.exerciseSets, (item, uuid) => <ExerciseItem {...item} key={uuid} title={exercise.title} />)}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.Done}>
          <StyledButton btnType="RedSlim" clicked={this.createNextHandler}>
            create next workout
          </StyledButton>
        </div>
        <div className={styles.Done}>
          <StyledButton btnType="SilverBig" clicked={this.doItLaterHandler}>
            do it later
          </StyledButton>
        </div>
      </Modal>
    );
  }
}

export default Summary;

function ExerciseItem({ title, sets, reps, weight }) {
  return (
    <div className={styles.Row}>
      <div className={styles.Exercise}>{title}</div>
      <div className={styles.ExerciseValues}>
        <div className={styles.ExerciseItem}>{sets}</div>
        <div className={styles.ExerciseItem}>x</div>
        <div className={styles.ExerciseItem}>{reps}</div>
        <div className={styles.ExerciseItem}>@</div>
        <div className={styles.ExerciseItem}>{weight}</div>
        <div>lbs</div>
      </div>
    </div>
  );
}
