import reduce from 'lodash/reduce';
import * as types from './_types';
import { initLoadFromFirebase, reInitConfig } from './logbook';
import { loginSuccess } from './auth';
import { auth as firebaseAuth, usersRef } from '../../firebase';
import history from '../../history';
import debug from 'debug';
import moment from 'moment';
import { peopleSet } from '../../mixpanel';
import { logout } from './auth';

const log = debug('Logbook:Redux:User');

export const updateProfileStart = () => {
  return {
    type: types.USER_UPDATE_START
  }
};

export const updateProfileSuccess = (profile) => {
  return {
    type: types.USER_UPDATE_SUCCESS,
    profile
  }
};

export const updateProfileHistory = (profileHistory) => {
  return {
    type: types.USER_UPDATE_HISTORY,
    profileHistory
  }
};

export const updateProfileFail = (error) => {
  return {
    type: types.USER_UPDATE_FAIL,
    error
  }
};

export const clearLocalData = () => {
  return {
    type: types.USER_CLEAR_LOCAL_DATA
  }
};

export const fetchFromFirebase = () => {
  return async dispatch => {
    if (!firebaseAuth.currentUser) {
      dispatch(logout());

      return 
    }

    const userId = firebaseAuth.currentUser.uid;
    const email = firebaseAuth.currentUser.email;
    const userProfileRef = usersRef.child(`${userId}/profile`);

    const latestProfile = await loadProfileData(userProfileRef);

    const profileHistory = await loadProfileHistory(userProfileRef);
    dispatch(updateProfileHistory(profileHistory));

    const profileKey = latestProfile && Object.keys(latestProfile)[0];
    const profileData = latestProfile && latestProfile[profileKey];

    const profile = {
      email,
      firstName: null,
      lastName: null,
      gender: null,
      bodyWeight: null,
      heightFt: null,
      heightIn: null,
      waistSize: null,
      hipSize: null,
      chestSize: null,
      leaderboardOptOut: false,
      ...(profileData || {})
    };
  
    log(profile);
    dispatch(updateProfileSuccess({
      email: profile.email,
      firstName: profile.firstName,
      gender: profile.gender,
      bodyWeight: profile.bodyWeight,
      heightFt: profile.heightFt,
      heightIn: profile.heightIn,
      waistSize: profile.waistSize,
      hipSize: profile.hipSize,
      chestSize: profile.chestSize,
      leaderboardOptOut: profile.leaderboardOptOut,
    }));

    const analyticProfile = {
      '$distinct_id': userId
    };
    if (profile.hasOwnProperty('firstName')) {
      analyticProfile['$first_name'] = profile.firstName;
    }
    if (profile.hasOwnProperty('lastName')) {
      analyticProfile['$last_name'] = profile.lastName;
    }
    if (profile.hasOwnProperty('email')) {
      analyticProfile['$email'] = profile.email;
    }
    if (profile.hasOwnProperty('createdAt')) { // TODO set_once? not implemented
      analyticProfile['$created'] = profile.createdAt;
    }
    if (profile.hasOwnProperty('role')) {
      analyticProfile['Role'] = profile.role;
    }
    if (profile.hasOwnProperty('bodyWeight')) {
      analyticProfile['Weight'] = profile.bodyWeight;
    }
    if (profile.hasOwnProperty('waistSize')) {
      analyticProfile['Waist'] = profile.waistSize;
    }
    if (profile.hasOwnProperty('hipSize')) {
      analyticProfile['Hips'] = profile.hipSize;
    }
    if (profile.hasOwnProperty('chestSize')) {
      analyticProfile['Chest'] = profile.chestSize;
    }
    if (profile.hasOwnProperty('heightFt') && profile.hasOwnProperty('heightIn')) {
      analyticProfile['Height'] = `${profile.heightFt}ft ${profile.heightIn}in`
    }
    peopleSet(analyticProfile);

    dispatch(initLoadFromFirebase());
    dispatch(reInitConfig());

    dispatch(loginSuccess(userId));
  }
};

export const updateProfile = (newData, redirect = null) => {
  return async dispatch => {
    if (!firebaseAuth.currentUser) {
      dispatch(logout());

      return
    }

    dispatch(updateProfileStart());

    const userId = firebaseAuth.currentUser.uid;
    const userProfileRef = usersRef.child(`${userId}/profile`);
    const date = moment().format('YYYY-MM-DD');
    const timestamp = moment().unix();
    const updateData = { ...newData, timestamp, date }

    const latestProfile = await loadProfileData(userProfileRef);

    const profileKey = latestProfile && Object.keys(latestProfile)[0];
    const profileData = latestProfile && latestProfile[profileKey];

    if (!profileData || profileData.date !== date) {
      const key = userProfileRef.push().key;
      updateProfileData(key, dispatch, userProfileRef, updateData, redirect);
    } else {
      updateProfileData(profileKey, dispatch, userProfileRef, { ...profileData, ...updateData }, redirect)
    }
  };
};

export const setConnectionStatus = (isConnected) => {
  return {
    type: types.USER_SET_CONNECTION_STATUS,
    isConnected
  }
}

async function loadProfileHistory(userProfileRef) {
  const userProfile = userProfileRef.orderByChild('timestamp');

  const profileHistory = await new Promise((resolve) => {
    userProfile.once('value', (snap) => {
      if (snap.exists()) {
        resolve(snap.val())
      } else {
        resolve()
      }
    })
  });

  return reduce(profileHistory, (acc, profile) => ([...acc, profile]), [])
    .sort((a, b) => b.timestamp - a.timestamp);
}

async function loadProfileData(userProfileRef) {
  const userProfile = userProfileRef.orderByChild('timestamp').limitToLast(1);

  return new Promise((resolve) => {
    userProfile.once('value', (snap) => {
      if (snap.exists()) {
        resolve(snap.val())
      } else {
        resolve()
      }
    })
  });
}

async function updateProfileData(key, dispatch, userProfileRef, data, redirect) {
  try {
    await userProfileRef.child(key).update(data);

    dispatch(updateProfileSuccess(data));

    const profileHistory = await loadProfileHistory(userProfileRef);
    dispatch(updateProfileHistory(profileHistory));

    if (redirect) {
      log('updateProfile have optional redirect:', redirect);
      history.push(redirect);
    }
  } catch (error) {
    log(error);
    dispatch(updateProfileFail(error));
  }
}
