import constants from './../../constants';

const {
  DEFAULT_BAR_WEIGHT,
  SMALL_BAR_WEIGHT,
  NEAREST_WEIGHTS,
  SETS_COUNT,
  SETS_COUNT_50,
  SETS_COUNT_25,
  SETS_COUNT_10,
  SETS_COUNT_80,
} = constants;

const weightToCoefficient = (weight) => {
  if (weight <= 100) {
    return 0.75;
  }

  if (weight <= 200) {
    return 1;
  }

  if (weight <= 300) {
    return 1.5;
  }

  if (weight <= 500) {
    return 2;
  }

  return 3;
}

const toWeight = (weight) => (value) => (value * weightToCoefficient(weight));

let roundPlate = (weight, addRound) => {
  const roundedWeight = roundWeight(weight);

  const easyIncrementWeight = NEAREST_WEIGHTS
    .find(weight => roundedWeight >= weight - addRound && roundedWeight <= weight + addRound);

  return easyIncrementWeight || roundedWeight;
};


export default class WarmupLogic {
  constructor({ exerciseId, exerciseWeight }) {
    this.exerciseId = exerciseId;
    this.exerciseWeight = exerciseWeight;
    this.stopWarmupWeight = this.exerciseWeight - toWeight(this.exerciseWeight)(10);
    this.warmups = [];
    this.weights = [];
    this.reps = 5;
    this.repsDecrement = 1;
    this.barWeight = getBarWeight(exerciseId, this.exerciseWeight);
    this.setsCount = Infinity;
    this.prevRepWeight = this.barWeight;
    this.lightWorkset = 140;

    const isManualException = this.isManualException();
    if (isManualException) {
      return this.warmups;
    }

    this.applyExceptionConfig();
    this.addInitWarmups();
    // light workset. 4 times warmup should be removed
    this.applyLightWorksetConfig();
    this.calculateIncrementJumpConfig();
    this.runCalculation();

    return this.warmups;
  }

  isBetween = (value, low, high) => (value <= high && value >= low);

  runCalculation = () => {
    while (this.prevRepWeight < this.exerciseWeight) {
      // skip if warmups calculated
      if (this.weights.length >= this.setsCount) break;

      const dirtyWeight = this.prevRepWeight + this.incrementJump;

      if (dirtyWeight >= this.exerciseWeight) break;

      // decrease reps
      if (this.reps >= 2) this.reps = this.reps - this.repsDecrement;

      this.prevRepWeight += this.incrementJump;

      let addRound = toWeight(this.prevRepWeight)(10);

      if (this.exerciseId === 'deadlift' && this.exerciseWeight > 240) {
        addRound = 20;
      }

      const repWeight = roundPlate(this.prevRepWeight, addRound);

      if (repWeight > this.stopWarmupWeight) break;

      this.prevRepWeight = this.exerciseId === 'deadlift' ? repWeight : dirtyWeight;

      if (!this.weights.includes(repWeight)) {
        this.addWarmup(repWeight);
      }
    }
  }

  calculateIncrementJumpConfig = () => {
    this.incrementJump = this.calculateIncrementJump(SETS_COUNT);

    if (this.incrementJump >= 75) {
      this.incrementJump = this.calculateIncrementJump(SETS_COUNT_80);
    } else if (this.incrementJump >= 50) {
      this.incrementJump = this.calculateIncrementJump(SETS_COUNT_50);
    } else if (this.incrementJump <= 25) {
      this.incrementJump = this.calculateIncrementJump(SETS_COUNT_25);
    } else if (this.incrementJump <= 10) {
      this.incrementJump = this.calculateIncrementJump(SETS_COUNT_10);
    }
  }

  calculateIncrementJump = SETS_COUNT => Math.ceil(((this.exerciseWeight - this.prevRepWeight) / SETS_COUNT) * 100) / 100;

  addWarmup = (weight) => {
    const set = this.warmups.length + 1;
    const reps = this.reps;

    this.warmups.push({ set, reps, weight });
    this.weights.push(weight);
  }

  applyLightWorksetConfig = () => {
    // light workset. 4 times warmup should be removed
    if (this.exerciseWeight <= this.lightWorkset && this.exerciseId !== 'deadlift') {
      this.reps = 4;
    }
  }

  addInitWarmups = () => {
    switch (this.exerciseId) {
      case 'deadlift':
        // Deadlift warm ups start at one set
        this.addWarmup(this.prevRepWeight);
        break;
      default:
        this.addWarmup(this.barWeight); // empty bar - set 1
        this.addWarmup(this.barWeight); // empty bar - set 2
        break;
    }
  }

  applyExceptionConfig = () => {
    switch (this.exerciseId) {
      case 'deadlift':
        // Deadlift warm ups start at 135lbs unless:
        //   * Work set is under 185lbs, first warm up is 95lbs
        //   * Work set is under 135lbs, first warm up is 65lbs
        if (this.exerciseWeight <= 135) {
          this.prevRepWeight = 65;
          this.setsCount = 2;
        } else if (this.exerciseWeight <= 185) {
          this.prevRepWeight = 95;
          this.setsCount = 2;
        } else if (this.exerciseWeight <= 240) {
          this.prevRepWeight = 135;
          this.setsCount = 2;
        } else {
          this.prevRepWeight = 135;
        }

        break;
      default:
        break;
    }
  }

  isManualException = () => {
    if (this.isBetween(this.exerciseWeight, 180, 190) && this.exerciseId !== 'deadlift') {
      this.warmups = [
        { set: 1, reps: 5, weight: 45 },
        { set: 2, reps: 5, weight: 45 },
        { set: 3, reps: 4, weight: 95 },
        { set: 4, reps: 3, weight: 115 },
        { set: 5, reps: 2, weight: 155 }
      ];

      return true;
    } else if (this.isBetween(this.exerciseWeight, 310, 320) && this.exerciseId !== 'deadlift') {
      this.warmups = [
        { set: 1, reps: 5, weight: 45 },
        { set: 2, reps: 5, weight: 45 },
        { set: 3, reps: 4, weight: 95 },
        { set: 4, reps: 3, weight: 135 },
        { set: 5, reps: 2, weight: 225 },
        { set: 5, reps: 1, weight: 275 }
      ];

      return true;
    } else if (this.isBetween(this.exerciseWeight, 360, 370) && this.exerciseId !== 'deadlift') {
      this.warmups = [
        { set: 1, reps: 5, weight: 45 },
        { set: 2, reps: 5, weight: 45 },
        { set: 3, reps: 4, weight: 95 },
        { set: 4, reps: 3, weight: 135 },
        { set: 5, reps: 2, weight: 225 },
        { set: 5, reps: 1, weight: 275 },
        { set: 5, reps: 1, weight: 315 }
      ];

      return true;
    } else if (this.isBetween(this.exerciseWeight, 140, 150) && this.exerciseId === 'deadlift') {
      this.warmups = [
        { set: 1, reps: 5, weight: 95 },
        { set: 2, reps: 4, weight: 115 },
      ];

      return true;
    }
  }
}

export const roundWeight = (value) => {
  const mod = value % 5;
  let correction = mod && 5 - mod;

  if (mod && mod < 2.5) {
    correction = -mod
  }

  return value + correction;
}

export const getBarWeight = (exerciseId, exerciseWeight) => {
  let barWeight = DEFAULT_BAR_WEIGHT;

  // SPECIAL CONDITIONS
  if (['bench', 'press'].includes(exerciseId) && exerciseWeight <= 55) {
    // If work set is 55lbs or less, warm up with 33lbs bar (instead of 45lb bar)
    barWeight = SMALL_BAR_WEIGHT;
  }

  return barWeight;
}
