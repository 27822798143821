import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { firebase as firebaseConfig } from './config';

firebase.initializeApp(firebaseConfig);

export default firebase;
export const auth = firebase.auth();

const databaseRef = firebase.database().ref();
export const usersRef = databaseRef.child('users');
export const workoutsRef = databaseRef.child('workouts');
