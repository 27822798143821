import * as types from '../actions/_types';
import { updateObject } from '../../shared/utility';

export const initialState = {
  _loading: false,
  _error: null,
  pendingAuth: false,

  email: null,
  token: null,
  userId: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN_START:
      return updateObject(state, {
        _loading: true
      });
    case types.AUTH_LOGIN_PENDING:
      return updateObject(state, {
        _loading: false,
        pendingAuth: true,
        email: action.email,
      })
    case types.AUTH_LOGIN_SUCCESS:
      return updateObject(state, {
        _loading: false,
        pendingAuth: false,
        token: action.token,
        userId: action.userId
      });
    case types.AUTH_LOGIN_FAIL:
      return updateObject(state, {
        _loading: false,
        pendingAuth: true,
        _error: action.error
      });
    case types.AUTH_LOGOUT:
      return updateObject(state, {
        email: null,
        pendingAuth: false,
        token: null,
        userId: null,
        _error: null
      });
    default:
      return state;
  }
}
