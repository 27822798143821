import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import reduce from 'lodash/reduce';
import debug from 'debug';
import { track } from '../../mixpanel';
import classes from './Logbook.module.css';

import * as logbookActions from '../../store/actions/logbook';
import Footer from 'components/View/Footer/Footer';
import StyledButton from 'components/UI/StyledButton/StyledButton';
import StyledButtonWrap from 'components/UI/StyledButton/StyledButtonWrap/StyledButtonWrap';
import TrainingItem from './TrainingItem/TrainingItem';
import Spinner from '../../components/UI/Spinner/Spinner';
import View from '../../components/View/View';
import Navbar from '../../components/View/Navbar/Navbar';
import NavbarRight from '../../components/View/Navbar/NavbarRight/NavbarRight';
import NavbarTitle from '../../components/View/Navbar/NavbarTitle/NavbarTitle';
import NavbarLeft from '../../components/View/Navbar/NavbarLeft/NavbarLeft';
import DrawerToggler from '../../components/View/SideDrawer/DrawerToggler/DrawerToggler';
import Page from '../../components/View/Page/Page';
import PageContent from '../../components/View/PageContent/PageContent';
import history from '../../history';

import { openWorkout } from '../../helpers/common';

const log = debug('Logbook:Logbook');

class Logbook extends Component {
  state = {
    showSideDrawer: false
  };

  componentDidMount() {
    track('Load History and Next Workouts from Firebase');
    log('Load history & next from firebase');
    this.props.onLoadFromFirebase();
  }

  sideDrawerClosedHandler = () => this.setState({ showSideDrawer: false });
  sideDrawerTogglerHandler = () => this.setState(prevState => ({ showSideDrawer: !prevState.showSideDrawer }));

  newWorkoutHandler = () => history.push('/workout/create');

  render() {
    const total = Object.keys(this.props.workouts).length - 1;
    // TODO: fix loading

    return (
      <View
        showSideDrawer={this.state.showSideDrawer}
        sideDrawerClosedHandler={this.sideDrawerClosedHandler}
      >
        <Navbar>
          <NavbarLeft>
            <DrawerToggler clicked={this.sideDrawerTogglerHandler} />
          </NavbarLeft>
          <NavbarTitle title="My Logbook" leftOffset />
          <NavbarRight />
        </Navbar>
        <Page>
          {this.props.loading &&
            <PageContent withNavbar>
              <Spinner />
            </PageContent>
          }
          {!this.props.loading &&
            <PageContent withNavbar>
              <div className={classes.Wrapper}>
                {reduce(this.props.workouts, (acc, item, key) => ([ ...acc, (
                  <TrainingItem
                    key={key}
                    lastOne={total === acc.length}
                    date={item.date}
                    exercises={item.exercises}
                    note={item.note}
                    editClicked={() => openWorkout(key, item.date, item.isCompleated)}
                  />
                )]), [])}
              </div>
            </PageContent>
          }
          <Footer>
            <StyledButton clicked={this.newWorkoutHandler}>
              <StyledButtonWrap caption="new workout" />
            </StyledButton>
          </Footer>
        </Page>
      </View>
    );
  }
}

const mapStateToProps = reduxState => ({
  loading: reduxState.logbook._loading,
  workouts: reduxState.logbook.workouts,
});

const mapDispatchToProps = dispatch => ({
  onLoadFromFirebase: () => dispatch(logbookActions.initLoadFromFirebase())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Logbook)
);
