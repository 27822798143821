import React from 'react';

import classes from './DrawerToggler.module.css';
import Icon from '../../../UI/Icon/Icon';

const DrawerToggler = (props) => (
  <div
    className={classes.DrawerToggle}
    onClick={props.clicked}>
    <Icon name='menu'/>
  </div>
);

export default DrawerToggler;