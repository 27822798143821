import reduce from 'lodash/reduce';
import groupBy from 'lodash/groupBy';
import * as types from '../actions/_types';
import { exercises as exercisesList } from 'config';

let config = reduce(exercisesList, (acc, exercise, key) => {
  return {...acc, [key]: {
    id: key,
    title: exercise.title,
    pos: exercise.pos,
    mandatory: exercise.mandatory || false,
    exerciseSets: {},
  }}
}, {});

export const initialState = {
  workouts: {},
  queue: [],
  config,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.RE_INIT_LOGBOOK_CONFIG:
      return { ...state, config: reduce({ ...config, ...state.config, }, (acc, exercise, key) => {
        if (exercise.individualSets) {
          const exerciseSetsArray = reduce(exercise.individualSets, (acc, sets, key) => {
            return [...acc, { uuid: key, ...sets }];
          }, []);
          const groupedExerciseSets = groupBy(exerciseSetsArray, (value) => `${value.reps}${value.weight}`);
          const exerciseSets = reduce(groupedExerciseSets, (acc, exercises) => {
            const [{ uuid, ...restExercise }] = exercises;
            return { ...acc, [uuid]: { ...restExercise, sets: exercises.length } };
          }, {});

          let newExercise = { ...exercise, exerciseSets };

          delete newExercise.individualSets;

          return { ...acc, [key]: newExercise };
        }

        return { ...acc, [key]: exercise };
      }, {}) };
    case types.INIT_LOGBOOK:
      return { ...state, workouts: action.workouts };
    case types.CLEAR_LOGBOOK_DATA:
      return { ...state, workouts: {} };
    case types.WORKOUT_QUEUE:
      delete state.queue[action.key];

      return { ...state, queue: [ action.workout, ...(state.queue || []) ] };
    case types.WORKOUT_DEQUEUE:
      let idx1 = state.queue.findIndex(queued => action.workoutId in queued);
      if (idx1 !== -1) {
        state.queue.splice(idx1, 1);
      }

      return { ...state, queue: state.queue };
    case types.WORKOUT_ADD_TO_LOGBOOK:
      delete state.workouts[action.key];

      return { ...state, workouts: { ...action.workout, ...(state.workouts || {}) } };
    case types.DELETE_WORKOUT_LOGBOOK:
      delete state.workouts[action.workoutId];

      // If record is still in queue, remove it as well
      let idx2 = state.queue.findIndex(queued => action.workoutId in queued);
      if (idx2 !== -1) {
       state.queue.splice(idx2, 1);
      }

      return { ...state, workouts: state.workouts, queue: state.queue };
    case types.UPDATE_LOGBOOK_CONFIG:
      const newConfig = reduce(state.config, (acc, exercise, key) => {
        if (key === action.exerciseId) {
          return { ...acc, [key]: { ...exercise, ...action.exercise, pos: config[key].pos } };
        }

        return { ...acc, [key]: { ...exercise, pos: config[key].pos } };
      }, {});

      return { ...state, config: newConfig }
    default:
      return state;
  }
}
