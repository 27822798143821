import React from 'react';

import { track } from '../../mixpanel';
import history from './../../history';
import constants from './../../constants';
import View from 'components/View/View';
import Navbar from '../../components/View/Navbar/Navbar';
import NavbarTitle from '../../components/View/Navbar/NavbarTitle/NavbarTitle';
import NavbarLeft from '../../components/View/Navbar/NavbarLeft/NavbarLeft';
import NavbarRight from '../../components/View/Navbar/NavbarRight/NavbarRight';
import NavbarButton from '../../components/View/Navbar/NavbarButton/NavbarButton';
import Page from '../../components/View/Page/Page';
import PageContent from '../../components/View/PageContent/PageContent';
import { getBarWeight } from '../Warmup/utils';

import PlateIcon from './PlateIcon/PlateIcon';
import styles from './Plate.module.css';

function Plates(props) {
  let platesWeights = [];
  let { weight, subtitle, exerciseId } = props.location.state;
  let barWeight = getBarWeight(exerciseId, weight);
  let weightOnSide = (weight - barWeight) / 2;

  React.useEffect(() => {
    track('Plates Opened');
  }, []);

  (function findPlatesWeights(weightOnSide) {
    let plateWeight = constants.PLATES_WEIGHTS.find(weight => weight <= weightOnSide);

    if (!plateWeight) {
      return;
    }

    platesWeights.push(plateWeight);

    weightOnSide -= plateWeight;

    if (weightOnSide > constants.PLATES_WEIGHTS[constants.PLATES_WEIGHTS.length - 1]) {
      findPlatesWeights(weightOnSide);
    }
  })(weightOnSide);

  return (
    <View>
      <Navbar>
        <NavbarLeft>
          <NavbarButton clicked={history.goBack}>close</NavbarButton>
        </NavbarLeft>
        <NavbarTitle title="Plates" subtitle={subtitle} />
        <NavbarRight empty/>
      </Navbar>

      <Page>
        <PageContent withNavbar whiteBg>
          <div>
            <div className={styles.title}>{weight} LBS</div>
            <Subtitle hasPlates={platesWeights.length > 0} />
            <div className={styles.iconsWrapper}>
              {platesWeights.map((weight, index) => <PlateIcon key={`${weight}${index}`} weight={weight} />)}
            </div>
          </div>
        </PageContent>
      </Page>
    </View>
  )
}

export default Plates;

function Subtitle(props) {
  if (props.hasPlates) {
    return <div className={styles.subtitle}>Plates for each side (lbs):</div>;
  }

  return <div className={styles.subtitle}>No plates on bar</div>;
}
