import React, { Component } from "react";

import { track } from "mixpanel";
import styles from "./styles.module.css";

import Modal from "components/Modal/Modal";

class ConfirmModal extends Component {
  componentDidMount() {
    track("Confirm Modal Opened");
  }

  okClickHandler = () => {
    track("Ok Clicked");
    this.props.okClicked();
  };

  cancelClickHandler = () => {
    track("Cancel Clicked");
    this.props.cancelClicked();
  };

  render() {
    return (
      <Modal isOpen={this.props.isOpen} shouldCloseOnOverlayClick={false}>
        <div className={styles.ConfirmText}>{this.props.confirmMessage}</div>
        <div className={styles.ConfirmPrompt}>{this.props.confirmPrompt}</div>
        <div className={styles.ButtonRow}>
          <div className={styles.OkButton} onClick={this.okClickHandler}>
            Yes
          </div>
          <div
            className={styles.CancelButton}
            onClick={this.cancelClickHandler}
          >
            No
          </div>
        </div>
      </Modal>
    );
  }
}

export default ConfirmModal;
