import React, { Component } from 'react';

import { track } from 'mixpanel';
import styles from './styles.module.css';

import StyledButton from 'components/UI/StyledButton/StyledButton';
import Modal from 'components/Modal/Modal';

class Summary extends Component {
  componentDidMount() {
    track('Disconnected modal shown');
  }

  render() {
    const clickHandler = this.props.clicked;
    return (
      <Modal isOpen={this.props.isOpen} shouldCloseOnOverlayClick={false}>
        <div className={styles.DisconnectedModal}>
          <h1 className={styles.Title}>It appears you have no internet connection.</h1>
          <p className={styles.NoteTitle}>Please ensure airplane mode is off and you are connected to data or wifi.</p>
        </div>
        <div className={styles.Done}>
          <StyledButton btnType="RedSlim" clicked={clickHandler}>
            Retry
          </StyledButton>
        </div>
      </Modal>
    );
  }
}

export default Summary;
