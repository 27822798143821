import React, { Fragment } from 'react';

import classes from './Navbar.module.css';

import NavbarTitle from './NavbarTitle/NavbarTitle';

import DrawerToggler from '../SideDrawer/DrawerToggler/DrawerToggler';

const Navbar = props => {
  const drawerToggler = props.drawerTogglerClicked && (
    <DrawerToggler clicked={props.drawerTogglerClicked} />
  );

  const children = props.children ? (
    props.children
  ) : (
    <Fragment>
      <div className={classes.Box}>{drawerToggler}</div>
      <NavbarTitle title={props.title} subtitle={props.subtitle} />
      <div className={classes.Box} />
    </Fragment>
  );

  return <nav className={classes.Navbar}>{children}</nav>;
};

export default Navbar;
