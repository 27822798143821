import * as types from './_types';
import isEmpty from 'lodash/isEmpty';
import {v4 as uuid} from 'uuid';
import {auth as firebaseAuth, usersRef} from '../../firebase';
import {logout} from "./auth";
import { track } from '../../mixpanel';

export const initLogbook = workouts => {
  return {
    type: types.INIT_LOGBOOK,
    workouts,
  };
};

export const addWorkout = (workout, key) => {
  return {
    type: types.WORKOUT_ADD_TO_LOGBOOK,
    workout,
    key,
  };
};

export const queueWorkout = (workout, key) => {
  return {
    type: types.WORKOUT_QUEUE,
    workout,
    key,
  };
};

export const dequeueWorkout = (workoutId) => {
  return {
    type: types.WORKOUT_DEQUEUE,
    workoutId,
  };
};

export const removeWorkout = (workoutId) => {
  return {
    type: types.DELETE_WORKOUT_LOGBOOK,
    workoutId,
  };
};

export const reInitConfig = () => {
  return {
    type: types.RE_INIT_LOGBOOK_CONFIG,
  };
}

export const updateConfig = (exerciseId, exercise) => {
  return {
    type: types.UPDATE_LOGBOOK_CONFIG,
    exerciseId,
    exercise,
  };
}

export const clearLogbookData = () => {
  return {
    type: types.CLEAR_LOGBOOK_DATA,
  }
};

export const saveWorkout = workout => async dispatch => {
  console.log('saveWorkout: start');

  if (!firebaseAuth.currentUser) {
    dispatch(logout());

    return;
  }

  const userId = firebaseAuth.currentUser.uid;
  const userRef = usersRef.child(userId);
  const logbookRef = userRef.child('logbook');

  const key = logbookRef.push().key;
  console.log(`saveWorkout: key ${key}`);

  console.log('saveWorkout: dispatching queueWorkout');
  dispatch(queueWorkout({ [key]: workout }, key));

  console.log('saveWorkout: adding workout to logbook');
  await logbookRef.child(key).set(workout);

  console.log('saveWorkout: dispatching addWorkout');
  dispatch(addWorkout({ [key]: workout }, key));

  return key;
};

export const deleteWorkout = (workoutId, done) => async dispatch => {
  if (!firebaseAuth.currentUser) {
    dispatch(logout());

    return;
  }

  const userId = firebaseAuth.currentUser.uid;
  const userRef = usersRef.child(userId);

  await userRef.child(`logbook/${workoutId}`).remove();

  dispatch(removeWorkout(workoutId));

  done();
};

export const updateNote = (workoutId, note) => async dispatch => {
  if (!firebaseAuth.currentUser) {
    dispatch(logout());

    return;
  }

  const userId = firebaseAuth.currentUser.uid;
  const userRef = usersRef.child(userId);
  const workoutRef = userRef.child(`logbook/${workoutId}`);

  await workoutRef.update({ note });

  dispatch({ type: types.UPDATE_WORKOUT_NOTES });

  dispatch(loadFromFirebase());
};

export const compleateWorkout = (workoutId, callback) => async dispatch => {
  if (!firebaseAuth.currentUser) {
    dispatch(logout());

    return;
  }

  const userId = firebaseAuth.currentUser.uid;
  const userRef = usersRef.child(userId);
  const workoutRef = userRef.child(`logbook/${workoutId}`);

  await workoutRef.update({ isCompleated: true });

  dispatch(loadFromFirebase(callback));
};

export const markAsCreatedWorkout = (workoutId, callback) => async dispatch => {
  if (!firebaseAuth.currentUser) {
    dispatch(logout());

    return;
  }

  const userId = firebaseAuth.currentUser.uid;
  const userRef = usersRef.child(userId);
  const workoutRef = userRef.child(`logbook/${workoutId}`);

  await workoutRef.update({ isCreated: true });

  dispatch(loadFromFirebase(callback));
};

export const updateExerciseSets = (workoutId, exerciseId, uuid, update) => {
  return async dispatch => {
    if (!firebaseAuth.currentUser) {
      dispatch(logout());

      return;
    }

    const userId = firebaseAuth.currentUser.uid;
    const userRef = usersRef.child(userId);

    const exerciseSetsRef = userRef.child(`logbook/${workoutId}/exercises/${exerciseId}/exerciseSets/${uuid}`);

    await exerciseSetsRef.update({ ...update });

    const exerciseRef = userRef.child(`logbook/${workoutId}/exercises/${exerciseId}`);
    const exerciseValue = await exerciseRef.once('value');
    const exercise = exerciseValue.val();
    dispatch(updateConfig(exerciseId, exercise));

    dispatch(loadFromFirebase());
  }
}

export const removeExerciseSet = (workoutId, exerciseId, uuid) => {
  return async dispatch => {
    if (!firebaseAuth.currentUser) {
      dispatch(logout());

      return;
    }

    const userId = firebaseAuth.currentUser.uid;
    const userRef = usersRef.child(userId);

    const exerciseSetsRef = userRef.child(`logbook/${workoutId}/exercises/${exerciseId}/exerciseSets/${uuid}`);

    await exerciseSetsRef.remove();

    const exerciseRef = userRef.child(`logbook/${workoutId}/exercises/${exerciseId}`);
    const exerciseValue = await exerciseRef.once('value');
    const exercise = exerciseValue.val();
    dispatch(updateConfig(exerciseId, exercise));

    dispatch(loadFromFirebase());
  }
}

export const addExerciseSet = (workoutId, exerciseId, set) => {
  return async dispatch => {
    if (!firebaseAuth.currentUser) {
      dispatch(logout());

      return;
    }

    const userId = firebaseAuth.currentUser.uid;
    const userRef = usersRef.child(userId);

    const exerciseSetsRef = userRef.child(`logbook/${workoutId}/exercises/${exerciseId}/exerciseSets`);
    const key = exerciseSetsRef.push().key;

    await exerciseSetsRef.child(key).set(set);

    const exerciseRef = userRef.child(`logbook/${workoutId}/exercises/${exerciseId}`);
    const exerciseValue = await exerciseRef.once('value');
    const exercise = exerciseValue.val();
    dispatch(updateConfig(exerciseId, exercise));

    dispatch(loadFromFirebase());
  }
}

export const updateExercises = (workoutId, exercises, config) => {
  return async dispatch => {
    if (!firebaseAuth.currentUser) {
      dispatch(logout());

      return;
    }

    const userId = firebaseAuth.currentUser.uid;
    const userRef = usersRef.child(userId);

    const exercisesRef = userRef.child(`logbook/${workoutId}/exercises`);

    await exercisesRef.remove();

    const newExercises = exercises.map(exercise => {
      if (isEmpty(config[exercise].exerciseSets)) {
        return { ...config[exercise], exerciseSets: {
          [uuid()]: { pos: 1, sets: 1, reps: 5, weight: 45 }
        } };
      }

      return { ...config[exercise] }
    });

    newExercises.forEach(exercise => {
      dispatch(updateConfig(exercise.id, exercise));
    })

    const objectExercises = newExercises
      .reduce((acc, exercise) => ({ ...acc, [exercise.id]: exercise }), {});

    await exercisesRef.set(objectExercises);

    dispatch(loadFromFirebase());
  };
};

export const initLoadFromFirebase = (callback = () => {}) => {
  return async dispatch => {
    if (!firebaseAuth.currentUser) {
      dispatch(logout());

      return;
    }

    dispatch(updateConfig());
    const userId = firebaseAuth.currentUser.uid;
    const userRef = usersRef.child(userId);

    const query = userRef.child('logbook').orderByChild('timestamp');

    dispatch(clearLogbookData());

    query.on('child_added', snap => dispatch(addWorkout({ [snap.key]: snap.val()}, snap.key)));

    callback();
  }
};

export const loadFromFirebase = (callback = () => {}) => {
  return async dispatch => {
    if (!firebaseAuth.currentUser) {
      dispatch(logout());

      return;
    }

    dispatch(updateConfig());
    const userId = firebaseAuth.currentUser.uid;
    const userRef = usersRef.child(userId);

    const query = userRef.child('logbook').orderByChild('timestamp');

    query.on('child_added', snap => dispatch(addWorkout({ [snap.key]: snap.val()}, snap.key)));

    callback();
  }
};

export const syncQueuedWorkouts = () => {
  return async (dispatch, getState) => {
    if (!firebaseAuth.currentUser) {
      return;
    }

    let queuedWorkouts = getState().logbook.queue;
    console.log(`syncQueuedWorkouts: found ${queuedWorkouts.length} queued workouts`);

    const userId = firebaseAuth.currentUser.uid;
    const userRef = usersRef.child(userId);
    const logbookRef = userRef.child('logbook');

    for (const queued of queuedWorkouts) {
      const [key] = Object.keys(queued);
      console.log(`syncQueuedWorkouts: checking if record ${key} exists`);

      try {
        const snapshot = await logbookRef.child(key).get();
        if (!snapshot.exists()) {
          console.log('syncQueuedWorkouts: adding workout to logbook');
          await logbookRef.child(key).set(queued[key]);

          try {
            track('Queued workout synced', {
              workout_id: key
            });
          } catch {
            // Ignored
          }

          console.log('syncQueuedWorkouts: dispatching addWorkout');
          dispatch(addWorkout(queued, key));
        } else {
          console.log('syncQueuedWorkouts: skipping existing workout');
        }

        console.log('syncQueuedWorkouts: Removing from queue');
        dispatch(dequeueWorkout(key));
      } catch (e) {
        console.log(`syncQueuedWorkouts: failed to create, ${e}`);
      }
    }
  }
};
