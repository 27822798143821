import React from 'react';
import className from 'classnames';

import classes from './Selectbox.module.css';
import Icon from '../Icon/Icon';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const value = props.value || '';
  return (
    <div className={className(classes.Wrap, { [classes.FullWidth]: props.fullWidth })}>
      <div className={classes.Select}>{props.title}</div>
      <select
        className={classes.NativeSelect}
        onChange={props.changed}
        onClick={props.changed}
        value={value}
        disabled={props.disabled}
      >
        {props.options.map((opt) => {
          return <option
            key={opt.id}
            value={opt.id}
          >{opt.title}</option>
        })}
      </select>
      <div className={classes.Dropdown}><Icon name='arrow-down'/></div>
    </div>
  );
}
