import React from 'react';

import classes from './StyledButton.module.css';

export default class StyledButton extends React.Component {
  render () {
    const clickHandler = this.props.disabled ? () => {} : this.props.clicked;
    let klasses = [classes.Button, classes[this.props.btnType || 'Red']];
    if (this.props.disabled) {
      klasses.push(classes.Disabled)
    }
    if (this.props.disabledWhite) {
      klasses.push(classes.DisabledWhite)
    }
    return (
      <div
        ref={this.props.reference}
        className={klasses.join(' ')}
        onClick={clickHandler}
      >{this.props.children}</div>
    )
  }
}
