import React, { Component } from "react";

import classes from "./ItemInner.module.css";

export default class ItemInner extends Component {
  renderSuffix() {
    if (this.props.suffix) {
      return <span className={classes.Suffix}>{this.props.suffix}</span>;
    }
  }
  render() {
    const klasses = [].concat(classes.ItemLabel);
    if (this.props.hiddenLabel) {
      klasses.push(classes.HiddenItemLabel);
    }
    return (
      <div className={classes.ItemInner}>
        <div className={klasses.join(" ")}>
          {this.props.label}
          {this.renderSuffix()}
        </div>
        <div className={classes.InputWrap}>{this.props.children}</div>
      </div>
    );
  }
}
