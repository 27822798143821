export const updateObject = (oldObject, newProperties) => {
  return {
    ...oldObject,
    ...newProperties
  };
};

export const isExisty = value => value !== null && value !== undefined;

export const isEmpty = value =>
  value === '' || value === undefined || value === null;

export const getURLSearchParam = (rawSearchString, key) => {
  const params = new URLSearchParams(rawSearchString);
  return params.get(key);
};

