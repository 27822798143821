import reduce from 'lodash/reduce';
import map from 'lodash/map';
import moment from 'moment';
import history from './../history';
import constants from '../constants';

const isWorkoutValid = workout => {
  return reduce(workout.exercises, (acc, exercise) => {
    const isValid = map(exercise.exerciseSets, e => (
      e.weight !== '' && Number(e.weight) >= 0 &&
      e.sets !== '' && Number(e.sets) >= 0 &&
      e.reps !== '' && Number(e.reps) >= 0
    ));

    if (!isValid) {
      return [...acc, false];
    }
    return [...acc, true];
  }, []).every(Boolean);
};

const getWorkoutTypeFromDate = date => {
  const isNextWorkout = moment(date).isAfter(moment(), 'day');
  const isMissingWorkout = moment(date).isBefore(moment(), 'day');

  var wt = constants.WORKOUT_TYPES.Today;

  if (!moment(date).isValid()) {
    return wt;
  }

  if (isMissingWorkout) {
    wt = constants.WORKOUT_TYPES.Missing;
  }
  if (isNextWorkout) {
    wt = constants.WORKOUT_TYPES.Next;
  }
  return wt;
};

const openWorkout = (workoutId, date, isCompleated, isCreation) => {
  const isTodayWorkout = moment(date).isSame(moment(), 'day');
  const isCompleatedTodayWorkout = isCompleated && isTodayWorkout;
  const isNextWorkout = moment(date).isAfter(moment(), 'day');
  const isHistoryWorkout = moment(date).isBefore(moment(), 'day');

  if (isTodayWorkout && !isCompleatedTodayWorkout) {
    history.push(`/workout/today`, { workoutId });
  }

  if (isNextWorkout) {
    history.push(`/workout/next`, { workoutId });
  }

  if (isHistoryWorkout || isCompleatedTodayWorkout) {
    history.push(`/logbook/${workoutId}`, { isCreation });
  }
};

const delay = async (ms) => new Promise(res => setTimeout(res, ms));

export {
  openWorkout,
  getWorkoutTypeFromDate,
  isWorkoutValid,
  delay,
};
