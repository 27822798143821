import React from 'react';

export default class NavbarRight extends React.Component {
  render () {
    const styles = {};
    if (this.props.empty) {
      styles['width'] = '40px';
    }
    return <div style={styles}>{this.props.children}</div>
  }
}