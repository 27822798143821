import React from 'react';
import endsWith from 'lodash/endsWith';
import scrollIntoView from 'scroll-into-view';
import classnames from 'classnames';

import classes from './InputBox.module.css';
import { isEmpty } from '../../../shared/utility';

class InputBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.input = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  onChange = (event) => {
    const { max, changed } = this.props;

    const isAllowed = !/^[0-9.]*$/g.test(event.target.value);
    const isMaximum = max && Number(event.target.value) > max;
    const isModuleByHalf = Number(event.target.value) % 0.5 === 0
  
    if (isAllowed || isMaximum || !isModuleByHalf) {
      return;
    }

    this.setState({ value: event.target.value });

    if (Number(event.target.value) <= 999 && !endsWith(event.target.value, '.'))
      return changed(event)
  };

  onFocus = evt => scrollIntoView(evt.target, { time: 300, align: { top: 0.3 } }, () => {
    if (!this.input.current) {
      return;
    }

    this.input.current.focus();
  });

  render() {
    const { value } = this.state;
    const { disabled, wide, full, caption, pattern } = this.props;
    const inputClasses = classnames({
      [classes.Input]: true,
      [classes.InputDisabled]: disabled,
      [classes.InputWide]: wide,
      [classes.InputFull]: full,
    });
    const formattedValue = isEmpty(value) ? '' : value.toString();

    return (
      <div>
        <div className={inputClasses}>
          <input
            ref={this.input}
            pattern={pattern || '[0-9]*'}
            type='text'
            inputMode='decimal'
            step='0.5'
            value={formattedValue}
            disabled={disabled}
            onChange={this.onChange}
            onFocus={this.onFocus}
          />
        </div>
        <div className={classes.Caption}>{caption}</div>
      </div>
    )
  }
}

export default InputBox;
