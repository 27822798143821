import React from 'react';

import classes from './NavigationItems.module.css';

import NavigationItem from './NavigationItem/NavigationItem';

const NavigationItems = (props) => (
  <ul className={classes.NavigationItems} onClick={props.clicked}>
    <NavigationItem link='/logbook' exact >Logbook</NavigationItem>
    <NavigationItem link='/profile'>Personal Information</NavigationItem>
    <NavigationItem link='/statistics'>My progress</NavigationItem>
    <NavigationItem link='/logout'>Log Out</NavigationItem>
  </ul>
);

export default NavigationItems;
