import React from 'react';

import history from './../../history';
import View from 'components/View/View';
import Navbar from '../../components/View/Navbar/Navbar';
import NavbarTitle from '../../components/View/Navbar/NavbarTitle/NavbarTitle';
import NavbarLeft from '../../components/View/Navbar/NavbarLeft/NavbarLeft';
import NavbarRight from '../../components/View/Navbar/NavbarRight/NavbarRight';
import NavbarButton from '../../components/View/Navbar/NavbarButton/NavbarButton';
import Page from '../../components/View/Page/Page';
import PageContent from '../../components/View/PageContent/PageContent';
import WarmupItem from './WarmupItem/WarmupItem';

import WarmupLogic from './utils';
import styles from './Warmup.module.css';

export default function Warmup(props) {
  let { weight, title, exerciseId, additionalTitle } = props.location.state;

  const warmups = new WarmupLogic({ exerciseId, exerciseWeight: weight });

  let closeHandler = () => history.goBack();

  return (
    <View>
      <Navbar>
        <NavbarLeft>
          <NavbarButton clicked={closeHandler}>close</NavbarButton>
        </NavbarLeft>
        <NavbarTitle title="Warmups" subtitle={`${title} - ${additionalTitle || ''}${weight} lbs`} />
        <NavbarRight empty/>
      </Navbar>

      <Page>
        <PageContent withNavbar whiteBg>
          <div className={styles.itemsWrapper}>
            {warmups.map(item =>
              <WarmupItem
                key={item.set}
                title={title}
                reps={item.reps}
                weight={item.weight}
                exerciseId={exerciseId}  
              />
            )}
          </div>
        </PageContent>
      </Page>
    </View>
  )
}
