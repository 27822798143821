import React from 'react';

import classes from './NavbarTitle.module.css';

export default class NavbarTitle extends React.Component {
  render() {
    let styles = {};
    let container;
    if (this.props.subtitle) {
      container = (
        <div className={classes.TitleSmall}>
          {this.props.title}
          <div className={classes.TitleSubTitle}>{this.props.subtitle}</div>
        </div>
      );
    } else if (this.props.title) {
      container = <div className={classes.Title}>{this.props.title}</div>;
    } else {
      container = <div className={classes.Title}>{this.props.children}</div>;
    }

    if (this.props.leftOffset) {
      styles['position'] = 'relative';
      styles['left'] = '-20px';
    }

    return <div style={styles}>{container}</div>;
  }
}
