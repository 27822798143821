import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import { track } from '../../mixpanel';
import history from '../../history';

import * as logbookActions from '../../store/actions/logbook';

import View from '../../components/View/View';
import Exercise from '../../components/Exercise/Exercise';
import StyledButton from '../../components/UI/StyledButton/StyledButton';
import StyledButtonWrap from '../../components/UI/StyledButton/StyledButtonWrap/StyledButtonWrap';
import Navbar from '../../components/View/Navbar/Navbar';
import NavbarTitle from '../../components/View/Navbar/NavbarTitle/NavbarTitle';
import NavbarLeft from '../../components/View/Navbar/NavbarLeft/NavbarLeft';
import NavbarRight from '../../components/View/Navbar/NavbarRight/NavbarRight';
import Footer from '../../components/View/Footer/Footer';
import NavbarButton from '../../components/View/Navbar/NavbarButton/NavbarButton';
import Page from '../../components/View/Page/Page';
import PageContent from '../../components/View/PageContent/PageContent';
import Notes from '../TodayWorkout/Note';
import SummaryModal from './summary';
import constants from '../../constants';
import DisconnectedModal from '../../components/Modal/disconnected';

import {delay, isWorkoutValid} from "../../helpers/common";

const log = console.log;

class NextWorkout extends Component {
  state = {
    isDisconnectedShown: false,
    isSummaryShown: false,
    deleteConfirmed: false,
    submitDisabled: false,
  };

  constructor(props) {
    super(props);

    if (props.location.state && props.location.state.workoutId) {
      this.state.workoutId = props.location.state.workoutId;
    }
  }

  componentDidMount() {
    track('Next Workout Opened');
    // nav to logbook if no current workouts
  }

  retryDoneHandler = async () => {
    track("Retry next workout clicked")
    this.setState({isDisconnectedShown: false});
    await this.doneHandler();
  };

  doneHandler = async () => {
    this.setState({submitDisabled: true});
    if (!this.props.isConnected) {
      await delay(3000);

      if (!this.props.isConnected) {
        this.setState({submitDisabled: false, isDisconnectedShown: true});

        return;
      }
    };

    this.props.onDone(this.state.workoutId, () => {
      this.setState({ isSummaryShown: true, submitDisabled: false });
    });
  };

  onUpdateExerciseSets = (exerciseId, uuid, update) => {
    this.props.onUpdateExerciseSets(this.state.workoutId, exerciseId, uuid, update);
  }

  onRemoveExerciseSet = (exerciseId, uuid) => {
    this.props.onRemoveExerciseSet(this.state.workoutId, exerciseId, uuid);
  }

  onAddExerciseSet = (exerciseId, set) => {
    this.props.onAddExerciseSet(this.state.workoutId, exerciseId, set);
  }

  editWorkoutHandler = () => {
    const { workoutId } = this.state
    const workout = this.props.workouts[workoutId];
    const workoutType = constants.WORKOUT_TYPES.Next;
    history.push({
      pathname: '/edit-workout',
      state: { workout, workoutId, workoutType }
    });
  };

  hasProfileInfo = () => {
    return this.props.bodyWeight && this.props.heightFt && this.props.waistSize;
  };

  handleCancel = (isCreated) => () => {
    this.goToLogbook();
  }

  goToLogbook = () => history.replace('/logbook');

  deleteHandler = () => {
    this.props.onDelete(this.state.workoutId, this.goToLogbook);
  };

  confirmDelete = () => {
    this.setState({ deleteConfirmed: true });
  }

  renderBottomButton = (isWorkoutCreated, disabled) => {
    let caption = 'Create Next Workout';
    let clicked = this.doneHandler;

    if (isWorkoutCreated) {
      caption = 'Delete Workout';
      clicked = this.confirmDelete;
    }
    if (this.state.deleteConfirmed) {
      caption = 'Tap Again to Confirm';
      clicked = this.deleteHandler;
    }

    clicked = disabled ? () => {} : clicked;
    const disabledWhite = disabled ? true : false;

    return (
      <StyledButton
        clicked={clicked}
        disabled={disabled}
        disabledWhite={disabledWhite}
      >
        <StyledButtonWrap caption={caption} />
      </StyledButton>
    );

  }

  render() {
    const { workoutId } = this.state;
    const workout = this.props.workouts[workoutId];

    if (!this.hasProfileInfo()) {
      log('Redirect /profile?setup=1&redirect=/ Props: %o');
      return (
        <Redirect
          to={{
            pathname: '/profile',
            search: '?setup=1&redirect=/workout/create?setup=1'
          }}
        />
      );
    }

    if (!workout) {
      return (
        <Redirect
          to={{
            pathname: '/logbook'
          }}
        />
      );
    }

    const isWorkoutCreated = workout.isCreated;
    const title = isWorkoutCreated ? 'Edit Next Workout' : 'Add Next Workout';
    const workoutDate = moment(workout.date).local().format('ddd, MMM D, YYYY');
    const disabled = !isWorkoutValid(workout);

    return (
      <View>
        <Navbar>
          <NavbarLeft>
            <NavbarButton clicked={this.handleCancel(isWorkoutCreated)}>Back</NavbarButton>
          </NavbarLeft>
          <NavbarTitle title={title} subtitle={workoutDate} />
          <NavbarRight>
            <NavbarButton clicked={this.editWorkoutHandler}>Edit</NavbarButton>
          </NavbarRight>
        </Navbar>
        <Page>
          <PageContent withNavbar withFooter>
            {sortBy(workout.exercises, 'pos').map((exercise) => (
              <Exercise
                key={exercise.id}
                id={exercise.id}
                showPlates
                edit
                title={exercise.title}
                exerciseSets={exercise.exerciseSets}
                onUpdateExerciseSets={this.onUpdateExerciseSets}
                onRemoveExerciseSet={this.onRemoveExerciseSet}
                onAddExerciseSet={this.onAddExerciseSet}
              />
            ))}
            <Notes workoutId={workoutId} />
          </PageContent>
          <Footer>{this.renderBottomButton(isWorkoutCreated, this.state.submitDisabled || disabled)}</Footer>
          <SummaryModal workout={workout} isOpen={this.state.isSummaryShown} />
          <DisconnectedModal isOpen={this.state.isDisconnectedShown} clicked={this.retryDoneHandler} />
        </Page>
      </View>
    );
  }
}

const mapStateToProps = reduxState => {
  return {
    bodyWeight: reduxState.user.bodyWeight,
    heightFt: reduxState.user.heightFt,
    isConnected: reduxState.user.isConnected,
    waistSize: reduxState.user.waistSize,
    workouts: reduxState.logbook.workouts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDone: (workoutId, callback) => dispatch(logbookActions.markAsCreatedWorkout(workoutId, callback)),
    onDelete: (workoutId, done) => dispatch(logbookActions.deleteWorkout(workoutId, done)),
    onUpdateExerciseSets: (workoutId, exerciseId, uuid, update) => dispatch(logbookActions.updateExerciseSets(workoutId, exerciseId, uuid, update)),
    onRemoveExerciseSet: (workoutId, exerciseId, uuid) => dispatch(logbookActions.removeExerciseSet(workoutId, exerciseId, uuid)),
    onAddExerciseSet: (workoutId, exerciseId, set) => dispatch(logbookActions.addExerciseSet(workoutId, exerciseId, set)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NextWorkout);
