
export const WORKOUT_TYPES = {
  Today: { id: 1, display: `Today's`, route: 'today' },
  Missing: { id: 2, display: `Missing`, route: 'missing' },
  Next: { id: 3, display: `Next`, route: 'next' },
  New: { id: 4, display: `New`, route: 'new' }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  DEFAULT_BAR_WEIGHT: 45, // lbs == 20 kg
  SMALL_BAR_WEIGHT: 33, // lbs == 10 kg
  PLATES_WEIGHTS: [45, 25, 10, 5, 2.5, 1.25, 1, 0.75, 0.5],
  NEAREST_WEIGHTS: [95, 135, 185, 225, 275, 315, 365, 405, 455, 495, 545, 585, 635, 675, 725, 765, 815, 855, 905, 945, 995, 1035, 1085, 1125, 1175, 1215, 1265, 1305, 1355, 1395, 1445, 1485, 1535, 1575, 1625, 1665, 1715, 1755, 1805, 1845, 1895, 1935, 1985],
  SETS_COUNT: 4,
  SETS_COUNT_80: 6,
  SETS_COUNT_50: 5,
  SETS_COUNT_25: 3,
  SETS_COUNT_10: 2,
  WORKOUT_TYPES: {
    Today: { id: 1, display: `Today's`, route: 'today' },
    Missing: { id: 2, display: `Missing`, route: 'missing' },
    Next: { id: 3, display: `Next`, route: 'next' },
    New: { id: 4, display: `New`, route: 'new' }
  }
};
