import React from 'react';
import moment from 'moment';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ date }) => {
  const dayOfWeek = moment(date, 'YYYY-MM-DD').format('ddd');
  const today = moment().startOf('day');
  const isToday = moment(date, 'YYYY-MM-DD').isSame(today, 'd');

  const formattedValue = moment(date, 'YYYY-MM-DD').format(' MMM D, YYYY');

  return (
    <React.Fragment>
      {isToday ? 'Today' : dayOfWeek}, {formattedValue}
    </React.Fragment>
  );
};
