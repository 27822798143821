import React, { Component, Fragment } from 'react';

import classes from './View.module.css';

import SideDrawer from './SideDrawer/SideDrawer'

export default class View extends Component {

  // state = {
  //   showSideDrawer: false
  // };
  //
  //
  // sideDrawerClosedHandler = () => {
  //   this.setState({ showSideDrawer: false })
  // };
  //
  // sideDrawerTogglerHandler = () => {
  //   this.setState(prevState => {
  //     return { showSideDrawer: !prevState.showSideDrawer }
  //   })
  // };


  render () {
    return (
      <Fragment>
        <SideDrawer
          open={this.props.showSideDrawer}
          closed={this.props.sideDrawerClosedHandler}
        />
        <div className={classes.View}>{this.props.children}</div>
      </Fragment>
    );
  }
}