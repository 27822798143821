export const LOCAL_STORAGE_KEY = 'gym.state';

export const firebase = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
};

// should be alphabetically sorted
export const exercises = {
  squat: {
    title: 'Squat',
    pos: 0,
    mandatory: true
  },
  bench: {
    title: 'Bench',
    pos: 1,
    mandatory: true
  },
  press: {
    title: 'Press',
    pos: 2,
    mandatory: true
  },
  deadlift: {
    title: 'Deadlift',
    pos: 3,
    mandatory: true
  },
  chins: {
    title: 'Chins',
    pos: 4,
    mandatory: true
  },
  lat_pulldown: {
    title: 'Lat Pulldown',
    pos: 5,
    mandatory: true
  },
  power_clean: {
    title: 'Power Clean',
    pos: 6,
    mandatory: true
  },
  barbell_rows: {
    title: 'Barbell Rows',
    pos: 7
  },
  bodyweight_row: {
    title: 'Bodyweight Row',
    pos: 8
  },
  clean: {
    title: 'Clean',
    pos: 9
  },
  clean_jerk: {
    title: 'Clean and Jerk',
    pos: 10
  },
  close_grip_bench_press: {
    title: 'Close Grip Bench Press',
    pos: 11
  },
  curls: {
    title: 'Curls',
    pos: 12
  },
  deficit_deadlift: {
    title: 'Deficit Deadlift',
    pos: 13
  },
  dips: {
    title: 'Dips',
    pos: 14
  },
  echo_bike: {
    title: 'Echo Bike',
    pos: 15
  },
  front_squat: {
    title: 'Front Squat',
    pos: 16
  },
  halting_deadlift: {
    title: 'Halting Deadlift',
    pos: 17
  },
  high_bar_squat: {
    title: 'High Bar Squat',
    pos: 18
  },
  jerk: {
    title: 'Jerk',
    pos: 19
  },
  lyingTricepExtensions: {
    title: 'Lying Tricep Extensions',
    pos: 20
  },
  paused_bench: {
    title: 'Paused Bench',
    pos: 21
  },
  paused_deadlift: {
    title: 'Paused Deadlift',
    pos: 22
  },
  paused_s: {
    title: 'Paused Squat',
    pos: 23
  },
  pin_bench_press: {
    title: 'Pin Bench Press',
    pos: 24
  },
  pin_press: {
    title: 'Pin Press',
    pos: 25
  },
  pin_squat: {
    title: 'Pin Squat',
    pos: 26
  },
  power_snatch: {
    title: 'Power Snatch',
    pos: 27
  },
  prowler: {
    title: 'Prowler',
    pos: 28
  },
  pullups: {
    title: 'Pullups',
    pos: 29
  },
  rdl: {
    title: 'RDL',
    pos: 30
  },
  rack_pull: {
    title: 'Rack Pull',
    pos: 31
  },
  snatch: {
    title: 'Snatch',
    pos: 32
  },
  snatch_grip_deadlift: {
    title: 'Snatch Grip Deadlift',
    pos: 33
  },
  stiff_legged_deadlift: {
    title: 'Stiff Legged Deadlift',
    pos: 34
  },
  strict_press: {
    title: 'Strict Press',
    pos: 35
  }
};
