export const AUTH_LOGIN_START = 'AUTH_LOGIN_START';
export const AUTH_LOGIN_PENDING = 'AUTH_LOGIN_PENDING';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const USER_UPDATE_START = 'USER_UPDATE_START';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_CLEAR_LOCAL_DATA = 'USER_CLEAR_LOCAL_DATA';
export const USER_UPDATE_HISTORY = 'USER_UPDATE_HISTORY';
export const USER_SET_CONNECTION_STATUS = 'USER_SET_CONNECTION_STATUS';

export const WORKOUT_INIT_CONFIG = 'WORKOUT_INIT_CONFIG';
export const WORKOUT_SET_AGENDA = 'WORKOUT_SET_AGENDA';
export const WORKOUT_RESET = 'WORKOUT_RESET';
export const WORKOUT_SAVE_START = 'WORKOUT_SAVE_START';
export const WORKOUT_SAVE_SUCCESS = 'WORKOUT_SAVE_SUCCESS';
export const WORKOUT_INIT_TODAY = 'WORKOUT_INIT_TODAY';
export const WORKOUT_SET_SUMMARY = 'WORKOUT_SET_SUMMARY';
export const WORKOUT_ADD_TO_HISTORY = 'WORKOUT_ADD_TO_HISTORY';
export const WORKOUT_ADD_TO_NEXT = 'WORKOUT_ADD_TO_NEXT';
export const WORKOUT_DELETE_NEXT_SUCCESS = 'WORKOUT_DELETE_NEXT_SUCCESS';
export const WORKOUT_SWITCH_AGENDA_SUCCESS = 'WORKOUT_SWITCH_AGENDA_SUCCESS';
export const WORKOUT_CHANGE_WEIGHT_SUCCESS = 'WORKOUT_CHANGE_WEIGHT_SUCCESS';
export const WORKOUT_CHANGE_SETS_SUCCESS = 'WORKOUT_CHANGE_SETS_SUCCESS';
export const WORKOUT_CHANGE_REPS_SUCCESS = 'WORKOUT_CHANGE_REPS_SUCCESS';
export const WORKOUT_CHANGE_PARAMS_SUCCESS = 'WORKOUT_CHANGE_PARAMS_SUCCESS';
export const WORKOUT_LOADING_HISTORY_BEGIN = 'WORKOUT_LOADING_HISTORY_BEGIN';
export const WORKOUT_LOADING_NEXT_SUCCESS = 'WORKOUT_LOADING_NEXT_SUCCESS';
export const WORKOUT_LOADING_HISTORY_SUCCESS =
  'WORKOUT_LOADING_HISTORY_SUCCESS';
export const WORKOUT_HISTORY_EDIT_DRAFT = 'WORKOUT_HISTORY_EDIT_DRAFT';
export const WORKOUT_HISTORY_EDIT_DELETE_DRAFT =
  'WORKOUT_HISTORY_EDIT_DELETE_DRAFT';
export const WORKOUT_HISTORY_UPDATED_SUCCESS =
  'WORKOUT_HISTORY_UPDATED_SUCCESS';
export const WORKOUT_EXERCISE_EDIT = 'WORKOUT_EXERCISE_EDIT';
export const WORKOUT_EXERCISE_EDIT_SUCCESS = 'WORKOUT_EXERCISE_EDIT_SUCCESS';
export const WORKOUT_DELETE_BEGIN = 'WORKOUT_DELETE_BEGIN';
export const WORKOUT_DELETE_SUCCESS = 'WORKOUT_DELETE_SUCCESS';

export const INIT_LOGBOOK = 'INIT_LOGBOOK';
export const RE_INIT_LOGBOOK_CONFIG = 'RE_INIT_LOGBOOK_CONFIG';
export const WORKOUT_ADD_TO_LOGBOOK = 'WORKOUT_ADD_TO_LOGBOOK';
export const WORKOUT_QUEUE = 'WORKOUT_QUEUE';
export const WORKOUT_DEQUEUE = 'WORKOUT_DEQUEUE';
export const CHANGE_EXERCISE = 'CHANGE_EXERCISE';
export const DELETE_WORKOUT_LOGBOOK = 'DELETE_WORKOUT_LOGBOOK';
export const UPDATE_LOGBOOK_CONFIG = 'UPDATE_LOGBOOK_CONFIG';
export const CLEAR_LOGBOOK_DATA = 'CLEAR_LOGBOOK_DATA';
export const UPDATE_WORKOUT_NOTES = 'UPDATE_WORKOUT_NOTES';
