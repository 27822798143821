import React from 'react';

import classes from './SelectList.module.css';

import SelectItem from './SelectItem/SelectItem';

export default function SelectList(props) {
  const total = props.list.length - 1;

  return (
    <div className={classes.SelectList}>
      {props.list.map((exercise, index) => (
        <SelectItem
          key={exercise.id}
          id={exercise.id}
          lasOne={total === index}
          title={exercise.title}
          selected={exercise.selected}
          clicked={() => props.clicked(exercise.id)}
        />
      ))}
    </div>
  )
}