import * as React from 'react';

import styles from './styles.module.css';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
                    label,
                    onChange,
                    disabled,
                    labelClassName,
                    className,
                    checked
                }) => {
    const ref = React.useRef(null);

    const onChangeInput = (event) => {
        return onChange(event.target.value);
    };

    return (
        <div className={className}>
            <label className={labelClassName}>
                <input
                    ref={ref}
                    className={styles.Checkbox}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={onChangeInput}
                />
                <span className={styles.LabelText}>{label}</span>
            </label>
        </div>
    )
}
