import React from 'react';

import classes from './NavbarButton.module.css';

export default class NavbarButton extends React.Component {
  render() {
    const klasses = [classes.Button];
    if (this.props.disabled) {
      klasses.push(classes.ButtonDisabled);
      return <div className={klasses.join(' ')}>{this.props.children}</div>;
    }

    if (this.props.submit) {
      klasses.push(classes.ButtonSubmit);
    }

    if (this.props.small) {
      klasses.push(classes.ButtonSmall);
    }

    if (this.props.red) {
      klasses.push(classes.ButtonRed);
    }
    return (
      <div className={klasses.join(' ')} onClick={this.props.clicked}>
        {this.props.children}
      </div>
    );
  }
}
