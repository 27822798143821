import mixpanel from 'mixpanel-browser';
import debug from 'debug';

const log = debug('Logbook:Mixpanel');
const isRealTrack = process.env.REACT_APP_ENV !== 'dev';

export const init = (token) => {
  if (isRealTrack) mixpanel.init(token);
};

export const track = (evt, props = {}) => {
  log('Track: %s', evt);
  if (isRealTrack) mixpanel.track(`[TRAINEE] ${evt}`, props);
};

export const identify = (distinctId) => {
  log('Identify: %s', distinctId);
  if (isRealTrack) mixpanel.identify(distinctId);
};

export const peopleSet = (params) => {
  log('PeopleSet: %o', params);
  if (isRealTrack) mixpanel.people.set(params)
};