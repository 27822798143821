import React from 'react';
import { connect } from 'react-redux';
import * as logbookActions from '../../store/actions/logbook';

import classes from './Note.module.css';

function Notes({ workoutId, workouts, onChange, history }) {
  const workout = workouts[workoutId];
  const [notes, setNotes] = React.useState(workout.note);
  const onNoteChange = (e) => {
    setNotes(e.target.value);
    onChange(workoutId, e.target.value);
  };

  React.useEffect(() => {
    setNotes(workout.note);
  }, [workout.note]);

  return (
    <div className={classes.Container}>
      <div className={classes.Head}>
        <div className={history ? classes.TitleBlue : classes.Title}>Notes</div>
      </div>

      <div className={classes.NoteContainer}>
        <textarea
          value={notes}
          className={classes.Notes}
          maxLength={255}
          rows={6}
          cols={50}
          onChange={onNoteChange}
        />
      </div>
    </div>
  )
}

const mapStateToProps = reduxState => {
  return {
    workouts: reduxState.logbook.workouts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChange: (workoutId, note) => dispatch(logbookActions.updateNote(workoutId, note)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notes);