import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import { track } from '../../mixpanel';
import history from '../../history';

import * as logbookActions from '../../store/actions/logbook';

import View from '../../components/View/View';
import Exercise from '../../components/Exercise/Exercise';
import StyledButton from '../../components/UI/StyledButton/StyledButton';
import StyledButtonWrap from '../../components/UI/StyledButton/StyledButtonWrap/StyledButtonWrap';
import Navbar from '../../components/View/Navbar/Navbar';
import NavbarTitle from '../../components/View/Navbar/NavbarTitle/NavbarTitle';
import NavbarLeft from '../../components/View/Navbar/NavbarLeft/NavbarLeft';
import NavbarRight from '../../components/View/Navbar/NavbarRight/NavbarRight';
import Footer from '../../components/View/Footer/Footer';
import DrawerToggler from '../../components/View/SideDrawer/DrawerToggler/DrawerToggler';
import NavbarButton from '../../components/View/Navbar/NavbarButton/NavbarButton';
import Page from '../../components/View/Page/Page';
import PageContent from '../../components/View/PageContent/PageContent';
import SummaryModal from './summary';
import constants from './../../constants';
import Notes from './Note';

import { isWorkoutValid } from "../../helpers/common";

const log = console.log;

class TodayWorkout extends Component {
  state = {
    showSideDrawer: false,
    isSummaryShown: false,
  };

  constructor(props) {
    super(props);

    if (props.location.state && props.location.state.workoutId) {
      this.state.workoutId = props.location.state.workoutId;
    }
  }

  componentDidMount() {
    track('Today Workouts Opened');
    // nav to logbook if no current workouts
  }

  sideDrawerClosedHandler = () => this.setState({ showSideDrawer: false });
  sideDrawerTogglerHandler = () => this.setState(prevState => ({ showSideDrawer: !prevState.showSideDrawer }));

  doneHandler = () => {
    this.props.onDone(this.state.workoutId, () => {
      this.setState({ isSummaryShown: true });
    });
  };

  editWorkoutHandler = () => {
    const { workoutId } = this.state
    const workout = this.props.workouts[workoutId];
    const workoutType = constants.WORKOUT_TYPES.Today;
    history.push({
      pathname: '/edit-workout',
      state: { workout, workoutId, workoutType }
    });
  };

  hasProfileInfo = () => {
    return this.props.bodyWeight && this.props.heightFt && this.props.waistSize;
  };

  renderBottomButton = disabled => {
    const caption = 'Log Workout';
    const clicked = disabled ? () => {} : this.doneHandler;
    const disabledWhite = disabled ? true : false;

    return (
      <StyledButton disabled={disabled} disabledWhite={disabledWhite} clicked={clicked}>
        <StyledButtonWrap caption={caption} />
      </StyledButton>
    );
  };

  onUpdateExerciseSets = (exerciseId, uuid, update) => {
    this.props.onUpdateExerciseSets(this.state.workoutId, exerciseId, uuid, update);
  }

  onRemoveExerciseSet = (exerciseId, uuid) => {
    this.props.onRemoveExerciseSet(this.state.workoutId, exerciseId, uuid);
  }

  onAddExerciseSet = (exerciseId, set) => {
    this.props.onAddExerciseSet(this.state.workoutId, exerciseId, set);
  }

  render() {
    const { workoutId } = this.state;
    const workout = this.props.workouts[workoutId];

    if (!this.hasProfileInfo()) {
      log('Redirect /profile?setup=1&redirect=/ Props: %o');
      return (
        <Redirect
          to={{
            pathname: '/profile',
            search: '?setup=1&redirect=/workout/create?setup=1'
          }}
        />
      );
    }

    if (!workout) {
      return (
        <Redirect
          to={{
            pathname: '/logbook'
          }}
        />
      );
    }

    const workoutDate = moment(workout.date).local().format('ddd, MMM D, YYYY');
    const disabled = !isWorkoutValid(workout);

    return (
      <View
        showSideDrawer={this.state.showSideDrawer}
        sideDrawerClosedHandler={this.sideDrawerClosedHandler}
      >
        <Navbar>
          <NavbarLeft>
            <DrawerToggler clicked={this.sideDrawerTogglerHandler} />
          </NavbarLeft>
          <NavbarTitle title="Current Workout" subtitle={workoutDate} />
          <NavbarRight>
            <NavbarButton clicked={this.editWorkoutHandler}>Edit</NavbarButton>
          </NavbarRight>
        </Navbar>
        <Page>
          <PageContent withNavbar withFooter>
            {sortBy(workout.exercises, 'pos').map((exercise) => (
              <Exercise
                key={exercise.id}
                id={exercise.id}
                showPlates
                edit
                title={exercise.title}
                exerciseSets={exercise.exerciseSets}
                onUpdateExerciseSets={this.onUpdateExerciseSets}
                onRemoveExerciseSet={this.onRemoveExerciseSet}
                onAddExerciseSet={this.onAddExerciseSet}
              />
            ))}
            <Notes workoutId={workoutId} />
          </PageContent>
          <Footer>{this.renderBottomButton(disabled)}</Footer>
          <SummaryModal workout={workout} isOpen={this.state.isSummaryShown} />
        </Page>
      </View>
    );
  }
}

const mapStateToProps = reduxState => {
  return {
    bodyWeight: reduxState.user.bodyWeight,
    heightFt: reduxState.user.heightFt,
    waistSize: reduxState.user.waistSize,
    workouts: reduxState.logbook.workouts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDone: (workoutId, callback) => dispatch(logbookActions.compleateWorkout(workoutId, callback)),
    onUpdateExerciseSets: (workoutId, exerciseId, uuid, update) => dispatch(logbookActions.updateExerciseSets(workoutId, exerciseId, uuid, update)),
    onRemoveExerciseSet: (workoutId, exerciseId, uuid) => dispatch(logbookActions.removeExerciseSet(workoutId, exerciseId, uuid)),
    onAddExerciseSet: (workoutId, exerciseId, set) => dispatch(logbookActions.addExerciseSet(workoutId, exerciseId, set)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TodayWorkout);
